<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2>[개인정보취급방침]</h2>
        </div>
        <div class="flex-grow-1">
          <div class="abox-cont">
            [개인정보 수집항목]<br><br>
            회사는 회원가입, 쇼핑몰 이용, 서비스 신청 및 제공 등을 위해 다음과 같은 개인정보를 수집하고 있습니다. 회사는 개인의 주민등록번호 및 아이핀 정보를 받지 않습니다.<br>
            가. 개인정보 항목<br>
            회원 필수항목: 전자우편주소, 카카오톡 전화번호 + ID 및 이름<br>
            다만, 서비스 이용과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록 등이 생성되어 수집될 수 있습니다.<br>
            나. 수집방법: 쇼핑몰 회원가입 시의 회원가입 정보 및 고객센터를 통한 전화 및 온라인 상담<br><br>

            [개인정보 수집이용 목적]<br><br>

            회사의 개인정보 수집 목적은 최적화된 맞춤화 서비스를 제공하기 위함이며, 회사는 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있습니다.<br>
            서비스 이용에 따른 대금결제, 물품배송 및 환불 등에 필요한 정보를 추가로 수집할 수 있습니다.<br>
            회사는 개인정보를 수집, 이용목적 이외에 다른 용도로 이용하거나 회원의 동의 없이 제3자에게 이를 제공하지 않습니다.<br><br>

            [개인정보 보유 및 이용기간]<br><br>

            회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.<br>
            단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 개인정보를 보관합니다.<br>

            가. 상법 등 법령에 따라 보존할 필요성이 있는 경우<br>

            ① 표시 • 광고에 관한 기록<br>
            보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br>
            보존기간: 6개월<br><br>

            ② 계약 또는 청약철회 등에 관한 기록<br>
            보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br>
            보존기간: 5년<br><br>

            ③ 대금결제 및 재화 등의 공급에 관한 기록<br>
            보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br>
            보존기간: 5년<br><br>

            ④ 소비자의 불만 또는 분쟁처리에 관한 기록<br>
            보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br>
            보존기간: 3년<br><br>

            ⑤ 신용정보의 수집, 처리 및 이용 등에 관한 기록<br>
            보존근거: 신용정보의 이용 및 보호에 관한 법률<br>
            보존기간: 3년<br><br>

            ⑥ 본인확인에 관한 기록보존<br>
            보존근거: 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의5 및 시행령 제29조<br>
            보존기간: 6개월<br><br>

            ⑦ 접속에 관한 기록보존<br>
            보존근거: 통신비밀보호법 제15조의2 및 시행령 제41조<br>
            보존기간: 3개월<br><br>

            나. 기타, 회원의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.<br><br>

            [개인정보 제3자 제공]<br><br>

            가. 회사는 회원들의 개인정보를 개인정보의 수집이용 목적에서 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 제 3자에게 제공하지
            않습니다. 단, 아래의 경우에는 예외로 합니다.<br>
            ① 회원들이 사전에 공개 또는 제3자 제공에 동의한 경우<br>
            ② 법령의 규정에 의거하나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우<br>

            나. 회사가 제공하는 서비스를 통하여 주문 및 결제가 이루어진 경우, 상담 등 거래 당사자간 원활한 의사소통 및 배송 등 거래이행을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게
            제공합니다.<br>
            ① OO택배사: 주문자 이름, 수취인 이름 배송지 정보, 연락처<br>
            그 밖에 개인정보 제3자 제공이 필요한 경우에는 합당한 절차를 통한 회원의 동의를 얻어 제3자에게 개인정보를 제공할 수 있습니다.<br>

          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'

export default {
  name: 'PrivacyTerms',
  components: {
    Navi, Footer
  }
}
</script>

export default {
  namespaced: true,
  state: {
    subsSelectInfo: null,
    subsOrderItems: null,
    repeatOrderDTO: null,
    orderDTO: null
  },
  mutations: {
    set_Subs(state, info) {
      state.subsSelectInfo = info
    },
    set_SubsOrderItems(state, info) {
      state.subsOrderItems = info
    },
    set_RepeatOrderDTO(state, info) {
      state.repeatOrderDTO = info
    },
    remove_RepeatOrder(state) {
      state.subsSelectInfo = null
      state.subsOrderItems = null
      state.repeatOrderDTO = null
    },
    set_OrderDTO(state, info) {
      state.orderDTO = info
    },
    remove_OrderDTO(state) {
      state.orderDTO = null
    }
  },
  actions: {
    setSubsSelectInfo({ commit }, info) {
      commit('set_Subs', info)
    },
    setSubsOrderItems({ commit }, info) {
      commit('set_SubsOrderItems', info)
    },
    setRepeatOrderDTO({ commit }, info) {
      commit('set_RepeatOrderDTO', info)
    },
    removeRepeatOrder({ commit }) {
      commit('remove_RepeatOrder')
    },
    setOrderDTO({ commit }, info) {
      commit('set_OrderDTO', info)
    },
    removeOrderDTO({ commit }) {
      commit('remove_OrderDTO')
    }
  },
  getters: {
    subsSelectInfo: state => state.subsSelectInfo,
    subsOrderItems: state => state.subsOrderItems,
    repeatOrder: state => state.repeatOrderDTO,
    orderDTO: state => state.orderDTO
  }
}

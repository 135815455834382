<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont gray-wrapper">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-check"></i>주문완료</h2>
        </div>
        <div class="flex-grow-1">
          <div class="abox-cont">
            <div class="gbox text-center">
              <span class="fid">주문번호 : {{orderId}}</span>
              <span>고객님의 상품 배송이 시작되면<br>알림톡을 통해 안내해 드리겠습니다.</span>
            </div>
            <div class="d-grid">
              <a href="/orders" class="btn btn-xl btn-primary">주문정보 확인</a>
              <a href="/home" class="btn btn-xl btn-outline-primary mt-3">홈으로</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SubscriptionOrderComplete',
  components: {
    Navi, Footer
  },
  data() {
    return {
      token: this.$route.query.pg_token,
      resultCode: this.$route.query.resultCode,
      reserveId: this.$route.query.reserveId,
      tempReceiptId: this.$route.query.tempReceiptId,
      repeatOrderDTO: null,
      orderId: null,
      result: null
    }
  },
  created() {
    // this.repeatOrderDTO = JSON.parse(sessionStorage.getItem('repeatOrderDTO'))
    this.repeatOrderDTO = this.getRepeatOrder()
    this.repeatOrderDTO.orderDTO.paymentDTOs[0].pgToken = this.token
    this.repeatOrderDTO.orderDTO.paymentDTOs[0].resultCode = this.resultCode
    this.repeatOrderDTO.orderDTO.paymentDTOs[0].reserveId = this.reserveId
    this.repeatOrderDTO.orderDTO.paymentDTOs[0].tempReceiptId = this.tempReceiptId

    if (this.token) {
      axios.post('/api/repeatOrder/kakaoPayComplete', this.repeatOrderDTO).then(({ data }) => {
        console.log(data)
        this.result = data.message
        this.orderId = this.repeatOrderDTO.orderDTO.orderId
        this.removeRepeatOrder()
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    } else if (this.resultCode === 'Success') {
      axios.post('/api/repeatOrder/naverPayComplete', this.repeatOrderDTO).then(({ data }) => {
        console.log(data)
        this.result = data.message
        this.removeRepeatOrder()
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    } else {
      this.$router.push('/subscriptionPayment')
    }
  },
  methods: {
    ...mapGetters({
      getRepeatOrder: 'orderStore/repeatOrder'
    }),
    ...mapActions({
      removeRepeatOrder: 'orderStore/removeRepeatOrder'
    })
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="aside user-box">
    <div class="user-name">
      <div>
        <span class="name">{{ user.username }}</span>님
      </div>
      <div>
        <button @click="goModify" class="btn btn-sm btn-outline-primary">정보수정</button>
        <button class="btn btn-sm btn-outline-primary" @click.once="logout">로그아웃</button>
      </div>
    </div>
    <ul class="my-menu">
      <li>
        <a href="/subscriptions" :class="isActive('subscriptions')">구독관리</a>
      </li>
      <li>
        <a href="/orders" :class="isActive('orders')">주문조회</a>
      </li>
<!--      <li>
        <a href="/purchaseReviews" :class="isActive('purchaseReviews')">구매후기</a>
      </li>-->
      <li>
        <a href="/qna" :class="isActive('qna')">1:1문의</a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LGnb',
  props: {
    url: String
  },
  data() {
    return {
      user: {
        birthday: '',
        emailAddress: '',
        mobilePhone: '',
        userId: '',
        username: ''
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      axios.get('/api/user/me/').then(({ data }) => {
        this.user = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    isActive(u) {
      if (this.url === u) {
        return 'active'
      }
    },
    logout() {
      this.$store.dispatch('userStore/logout').then(() => {
        this.$router.push('/login')
      })
    },
    goModify() {
      this.$router.push('/userModify')
    }
  }
}
</script>

<style scoped>

</style>

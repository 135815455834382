<template>
  <body class="d-flex flex-column h-100">
  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-lg">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>

  <div class="container-fluid main-cont flex-grow-1" v-if="findStep === 1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-user"></i> 아이디 찾기</h2>
          <span>가입한 이메일 계정을 찾을 수 있어요</span>
        </div>
        <div class="p2 flex-grow-1">
          <form @submit.prevent="submitForm">
            <div class="abox-cont">
              <div class="mb-4">
                <!-- form-control에 정보가 정확하면 is-valid, 잘못입력하면 is-invalid 클래스를 추가해 주세요 -->
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-calendar"></i></span>
                  <input class="form-control binput" type="text" name="birthday" v-model="form.birthday"
                         :class="{ 'is-invalid': submitted && $v.form.birthday.$error}" placeholder="생년월일">
                  <div id="validation1" v-if="submitted && !$v.form.birthday.required" class="invalid-feedback">
                    생년월일을 올바르게 입력해주세요
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-screen-smartphone"></i></span>
                  <input class="form-control binput" type="text" name="mobilePhone" v-model="form.mobilePhone"
                         :class="{ 'is-invalid': submitted && $v.form.mobilePhone.$error}" placeholder="휴대폰번호('-')제외">
                  <div id="validation2" v-if="submitted && !$v.form.mobilePhone.required" class="invalid-feedback">
                    휴대폰번호를 올바르게 입력해주세요
                  </div>
                </div>
              </div>
              <div class="mb-3 d-grid">
                <button class="btn btn-xl btn-primary">다음</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid main-cont flex-grow-1" v-if="findStep ===2 && result !== 'Fail'">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-user"></i> 아이디 찾기</h2>
          <span>가입된 계정을 찾았습니다.</span>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="gbox">
              <span class="fid"> {{result}}</span>
            </div>
            <div class="mb-3 d-grid">
              <a href="/login" class="btn btn-xl btn-primary">로그인</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid main-cont flex-grow-1" v-if="findStep ===2 && result === 'Fail'">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-user"></i> 아이디 찾기</h2>
          <span>가입된 계정을 찾지 못했습니다.</span>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="mb-3 d-grid">
              <a href="/register" class="btn btn-xl btn-primary">회원가입</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Footer from '@/components/Footer'
import axios from 'axios';

export default {
  name: 'FindId',
  components: { Footer },
  data() {
    return {
      form: {
        birthday: null,
        mobilePhone: null,
        companyId: process.env.VUE_APP_COMPANY_ID
      },
      submitted: false,
      findStep: 1,
      result: null
    }
  },
  validations: {
    form: {
      birthday: {
        required
      },
      mobilePhone: {
        required
      }
    }
  },
  methods: {
    submitForm() {
      this.submitted = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const sForm = new FormData()
      sForm.append('birthday', this.form.birthday)
      sForm.append('mobilePhone', this.form.mobilePhone)
      sForm.append('companyId', this.form.companyId)

      axios.post('/api/findId', sForm).then(({ data }) => {
        console.log(data.result)
        this.findStep = 2
        this.result = data.result
      }).catch((error) => {
        this.findStep = 2
        this.result = 'Fail'
        console.log(error)
      })
    },
    back() {
      if (this.findStep === 1) {
        this.$router.go(-1)
      } else {
        this.findStep = 1
        this.result = null
        this.submitted = false
      }
    }
  }
}
</script>

<style scoped>

</style>

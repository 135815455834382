<template>
  <div class="modal slide-right" id="manage-adress" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog side-canvas">
      <div class="modal-content side-wrap">
        <div class="side-header">
          <h3>배송지 목록</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="side-body">
          <div class="adress-none" v-if="deliveryList === null || deliveryList.length === 0">
            <i class="icon-map"></i>
            <span>등록된 배송지가<br>없습니다.</span>
          </div>
          <div class="d-grid adress-list">
            <div v-for="(address, idx) in deliveryList" :key="idx">
              <input type="radio" class="btn-check" name="Adress" :id="idx" :value="address" v-model="selectAddress" autocomplete="off">
              <label class="adress-box" :for="idx">
                <ul class="delivery-info">
                  <li class="name">{{ address.receiver }}</li>
                  <li class="adress">&#91;{{address.zipCode}}&#93; {{address.address1}} {{address.address2}}</li>
                  <li class="tel">{{ address.mobilePhone }}</li>
                </ul>
                <div class="checkmark-wrap">
                  <i class="checkmark-icon"></i>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="d-grid side-btnwrap">
          <button href="#" @click="setAddress" class="btn btn-xl btn-primary">선택완료</button>
          <button ref="close" data-bs-dismiss="modal"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeliveryList',
  data() {
    return {
      deliveryList: this.$parent.recentDeliveryList,
      selectAddress: null
    }
  },
  created() {
    this.getDeliveryList()
  },
  methods: {
    getDeliveryList() {
      axios.get('/api/order/recentDelivery').then(({ data }) => {
        this.deliveryList = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    setAddress() {
      this.$parent.modifyDelivery(this.selectAddress)
      this.$refs.close.click()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <body class="d-flex flex-column h-100">

  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2 text-center">
          <h1>환영합니다!</h1>
          <span>Monscent 회원가입이 완료 되었습니다.</span>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="gbox text-center">
              <span class="fid">고객님의 아이디는 <strong>{{ this.$route.params.info }}</strong> 입니다.</span>
            </div>
            <div class="mb-3 d-grid gap-3">
              <a href="/login" class="btn btn-xl btn-primary" id="startConfetti">시작하기</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
export default {
  name: 'RegisterComplete',
  methods: {
    back() {
      this.$router.push({ name: 'LoginPage' })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="main-cont">
    <section class="key-visual">
      <div class="back-layer"></div>
      <div class="key-text">
        <span class="key-tit">Monthly Scent, Monscent</span>
        <span class="key-tit-sub">매월 향으로 전하는 기억의 순간,<br>신선한 향으로 그 순간을 간직하세요</span>
      </div>
    </section>
    <section id="BrandStory" class="section-gap">
      <div class="container-limit">
        <h2 class="section-title">BRAND STORY</h2>
        <h3 class="main-title">매월 향으로 전하는 기억의 순간</h3>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="img-wrap">
              <img src="images/brand1.jpg" alt="brand Image">
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <p class="content-text">“뭐에 관한 곡이야?”<br>
              “음. 생각해봤는데 우리 함께 있는 사진이 없으니까요.<br>
              전 이 노래가 사진처럼 될 수 있다고 생각했어요.<br>
              우리의 삶 속에 함께 있는 이 순간을 캡쳐해 놓은”<br>
              “사진 멋지네. 그 속에 당신이 보여.”<br>
              “네, 전 그 안에 있어요.”<br>
              <strong>movie HER</strong><br><br>
              Monscent는 좋은 기억을 떠올리는 순간,<br>
              그 순간의 기억이 향기로 기억 될 수 있도록<br>
              향기로 그 기억의 순간을 전해 드립니다.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 브랜드 비디오 영역 -->
    <section id="Video">
      <div class="video-container">
        <!-- 유튜브 영상 크롬에서 오토플레이, Mute 기능은 ?rel=0&amp;autoplay=1&mute=1&amp;loop=1; -->
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/sPpyCCXmRl4?controls=0?rel=0&amp;&autoplay=1&mute=1&amp;playlist=sPpyCCXmRl4&loop=1&modestbranding=1" title="Monscent video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </section>

    <!-- 구독상품 장점 -->
    <section id="Advantage" class="section-gap bg-light-gray">
      <div class="container-limit">
        <h2 class="section-title">Our Scent, Monscent</h2>
        <h3 class="main-title">매월 좋은 향기로 여러분의 공간을 채워 보세요</h3>
        <div class="row pr-list">
          <div class="col-md-4 pb-5">
            <div class="img-wrap">
              <img src="images/pd1.png" alt="SERVICE INFO IMG">
            </div>
            <span class="tt">FRAGRANCE</span>
            <span class="ts">Monscent는 국제향료협회(IFRA) 가이드를 준수한 최고급 향료만을 사용해 만듭니다.</span>
          </div>
          <div class="col-md-4 pb-5">
            <div class="img-wrap">
              <img src="images/pd3.png" alt="SERVICE INFO IMG">
            </div>
            <span class="tt">SUBSCRIBE</span>
            <span class="ts">구독 주기에 맞춰 배송 전 알람 메시지와 제품을 무료로 배송해 드립니다.</span>
          </div>
          <div class="col-md-4 pb-5">
            <div class="img-wrap">
              <img src="images/pd2.png" alt="SERVICE INFO IMG">
            </div>
            <span class="tt">PERSONALIZATION</span>
            <span class="ts">개인별 배송주기 변경과 구독해지는 언제든지 간편하게 변경 하실 수 있습니다.</span>
          </div>
        </div>
      </div>
    </section>

    <!-- 구독제품 리스트 -->
    <section id="Subscribe" class="section-gap">
      <div class="container-limit">
        <h2 class="section-title">SUBSCRIBE</h2>
        <h3 class="main-title">
          소중한 당신의 공간이 향으로 가득하도록
          <span>처음 느낌의 그 향이 끊기지 않도록 매달 신선한 향으로 전달 해드려요</span>
        </h3>

      </div>
      <div class="container-limit">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-4 col-xs-6" v-for="item in itemList" :key="item.itemId">
            <div class="prod-item">
              <a :href="'/itemDetail?itemId='+item.itemId">
                <div class="img-wrap">
                  <img :src="middleImage(item)" width="100%" alt="상품이미지">
                </div>
                <span class="prod-name">{{ item.name }}</span>
                <div class="price">
                  <span class="text-price">{{ item.salePrice | makeComma }}</span>
                  <span class="text-unit">원</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ 영역 -->
    <section id="faq" class="customer-tab grey-bg">
      <div class="container-limit">
        <h2 class="section-title">FAQ</h2>
        <h3 class="main-title">고객님들이 자주 묻는 질문 입니다</h3>
        <span class="stitle">궁금한 분야를 클릭해보세요</span>
        <div class="nav nav-pills">
          <a v-for="(category,index) in faqCategory" :key="category.code"
             @click="selectCategory(category.code, index, category.codeName)"
             class="nav-link" :class="initCategory(index)" data-bs-toggle="pill" data-bs-target="#pills-faq" role="tab"
             :aria-controls="'pills-faq'+index" aria-selected="false">{{ category.codeName }}</a>
        </div>
        <div class="tab-content" v-if="listLoading && faqListOfCategory">
          <div class="tab-pane fade show active" id="pills-faq" role="tabpanel">
            <div class="inner-tab-cont">
              <span class="sub-title">{{ selectedIndex }}. {{ selectedCategoryName }}</span>
              <div class="accordion">
                <div class="accordion-item list-item" v-for="(faq,index) in faqListOfCategory" :key="index">
                  <h2 class="accordion-header" id="f11">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#answer'+index"
                            aria-expanded="true" :aria-controls="'answer'+index">
                      <span class="icon">Q</span>
                      <span class="item">{{ faq.title }}</span>
                    </button>
                  </h2>
                  <div :id="'answer'+index" class="accordion-collapse collapse" :aria-labelledby="'f'+index">
                    <div class="accordion-body">
                      <span class="icon">A</span>
                      <span class="item">{{ faq.answer }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="Contact" class="section-gap">
      <div class="container-limit">
        <h2 class="section-title">CONTACT US</h2>
        <h3 class="main-title">보다 궁금하신 사항이 있으실 경우 연락 해주세요</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="img-wrap">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d790.8957413480821!2d127.04759892919942!3d37.54132686211859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca53043a64bd3%3A0xa0db6c27837ca9b6!2z7Zes66Gc66i8642w7J20IOyEseyImOydvOuhnOygkA!5e0!3m2!1sko!2skr!4v1648432752953!5m2!1sko!2skr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div class="col-md-6">
            <div class="company-info">
              <span class="adress"><i class="icon-location-pin"></i> 04779 서울특별시 성동구 성수일로 1길 1 헬로먼데이 7층</span>
              <span class="tel"><i class="icon-phone"></i> 02-894-5575</span>
              <span class="email"><i class="icon-envelope"></i> contact@9billion.care</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Navi, Footer
  },
  data() {
    return {
      itemList: [],
      itemId: '',
      faqCategory: null,
      faqListOfCategory: null,
      listLoading: false,
      selectedIndex: 0,
      selectedCategoryName: ''
    }
  },
  created() {
    this.getItemList4Subscription()
    this.getFaqCategory()
  },
  methods: {
    getItemList4Subscription() {
      axios.get('/api/item/itemList4Subscription', { params: { page: '', size: '-1', sort: '' } }).then(({ data }) => {
        console.log(data.result.content)
        this.itemList = data.result.content
      }).catch((error) => {
        console.log(error)
      })
    },
    middleImage(item) {
      for (let i = 0; i < item.itemImages.length; i++) {
        if (item.itemImages[i].itemImageType === 'M') {
          return process.env.VUE_APP_STORAGE + item.itemImages[i].cloudPath
        }
      }
    },
    getFaqCategory() {
      axios.get('/api/faq/codeList/FAQCategory').then(({ data }) => {
        console.log(data.result)
        this.faqCategory = data.result
        this.selectCategory(this.faqCategory[0].code, 0, this.faqCategory[0].codeName)
      }).catch((error) => {
        console.log(error)
      })
    },
    selectCategory(categoryCode, index, categoryName) {
      this.listLoading = false
      axios.get('/api/faq/list', { params: { faqCategory: categoryCode } }).then(({ data }) => {
        console.log(data.result)
        this.faqListOfCategory = data.result.content
        this.selectedIndex = index + 1
        this.selectedCategoryName = categoryName
        this.listLoading = true
      }).catch((error) => {
        console.log(error)
      })
    },
    initCategory(index) {
      if (this.selectedIndex === index + 1) {
        return 'active'
      }
    }

  }
}
</script>

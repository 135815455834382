import { render, staticRenderFns } from "./QnaForm.vue?vue&type=template&id=6575812c&scoped=true&"
import script from "./QnaForm.vue?vue&type=script&lang=js&"
export * from "./QnaForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6575812c",
  null
  
)

export default component.exports
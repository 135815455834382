<template>
  <div id="app" v-cloak>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    titleTemplate: '%s'
  },
  mounted() {
    const KAKAO_JS_ID = '17a04a0a579e277463b75c5e02219cbc'
    // eslint-disable-next-line no-undef
    Kakao.init(KAKAO_JS_ID)
  },
  created() {
    console.log(process.env)
  }
}
</script>

<style>
[v-cloak] {
  display: none
}
</style>

<template>
  <body class="d-flex flex-column h-100">
  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button @click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2 border-b">
          <h2>1:1문의</h2>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="my-info-wrap">
              <span class="stitle">유형과 문의하실 내용을 작성해 주세요.</span>
              <div class="mb-3">
                <select class="form-select" v-model="form.faqCategory">
                  <option value="null">문의 유형을 선택해 주세요</option>
                  <option v-for="category in faqCategoryList" :key="category.code" :value="category.code">{{category.codeName}}</option>
                </select>
              </div>
              <div class="mb-3">
                <textarea class="form-control" rows="4" placeholder="문의하실 내용을 작성해 주세요" v-model="form.content"></textarea>
              </div>
              <div class="mb-3">
                <p>첨부 사진을 업로드 하실 수 있습니다.</p>
                <div class="file-upload">
                  <label for="UploadPhoto" class="btn btn-outline-primary"><i class="icon-picture"></i> 사진 업로드</label>
                  <input type="file" id="UploadPhoto" ref="qnaImage" @change="qnaImageSelect">
                </div>
                <div class="review-thumb" v-if="form.preloadImage">
                  <button class="btn-delete" @click="removeImage"><i class="icon-close"></i></button>
                  <img :src="form.preloadImage"  alt="문의이미지">
                </div>
              </div>
            </div>

            <div class="inline-btn-wrap">
              <button class="btn btn-outline-primary" @click="back">취소</button>
              <button class="btn btn-primary" @click="addQna">1:1문의신청</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader" v-show="progressing">
    <div class="lds-ellipsis">
      <img src="images/m.png" width="40px" alt="M">
      <div></div><div></div><div></div><div></div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer'

export default {
  name: 'QnaForm',
  components: {
    Footer
  },
  data() {
    return {
      progressing: false,
      faqCategoryList: null,
      form: {
        faqCategory: null,
        content: null,
        multipartFile: undefined,
        preloadImage: undefined
      }
    }
  },
  created() {
    this.getFaqCategory()
  },
  methods: {
    addQna() {
      if (!this.form.faqCategory) {
        alert('문의카테고리를 선택해주세요.')
        return
      }
      if (!this.form.content) {
        alert('구매후기 내용을 입력해주세요.')
        return
      }

      this.progressing = true

      const formData = new FormData()
      formData.append('faqCategory', this.form.faqCategory)
      formData.append('content', this.form.content)

      if (this.form.multipartFile) {
        formData.append('multipartFile', this.form.multipartFile)
      }

      axios.post('/api/qna/addQna', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({ data }) => {
        console.log(data)
        alert('문의가 등록되었습니다.')
        this.progressing = false
        this.$router.push('/qna')
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
        this.progressing = false
      })
    },
    getFaqCategory() {
      axios.get('/api/faq/codeList/FAQCategory').then(({ data }) => {
        console.log(data.result)
        this.faqCategoryList = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    qnaImageSelect(event) {
      const input = event.target
      // this.form.multipartFile = this.$refs.reviewImage.files[0]
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.form.preloadImage = e.target.result
          this.form.multipartFile = this.$refs.qnaImage.files[0]
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    removeImage() {
      this.form.preloadImage = undefined
      this.form.multipartFile = undefined
    },
    back() {
      this.$router.push('/qna')
    }
  }
}
</script>

<style scoped>

</style>

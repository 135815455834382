<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 max-limit" v-if="itemDetail">
    <div class="no-padding-wrap">
      <div class="detail-main">
        <div class="item-pic">
          <div id="detail-img" data-bs-interval="false" class="carousel slide detail-carousel" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button v-for="(largeImage,index) in itemLargeImages" :key="index"
                      type="button" data-bs-target="#detail-img" :data-bs-slide-to="index"
                      :class="{active : isFirst(index)}" aria-current="true" :aria-label="'Slide'+index">
                <img :src="gcpUrl+largeImage.cloudPath" alt="1">
              </button>
            </div>
            <div class="carousel-inner">
              <div v-for="(largeImage,idx) in itemLargeImages" :key="idx" class="carousel-item"
                   :class="{active : isFirst(idx)}">
                <img :src="gcpUrl+largeImage.cloudPath" alt="1">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#detail-img" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#detail-img" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <!--          <img :src="gcpUrl+itemDetail.itemImages[0].cloudPath" width="100%" alt="sample">-->
        </div>
        <div class="detail-info">
          <div class="info-wrap">
            <div class="product-name">
              <span>{{ itemDetail.name }}</span>
            </div>
            <div class="price">
              <span class="final"><em>30%</em> {{ itemDetail.salePrice | makeComma }}원</span>
              <span class="original">{{ itemDetail.price | makeComma }}원</span>
            </div>
            <div class="info">
              <ul>
                <li><em>무료배송</em></li>
              </ul>
            </div>
            <div class="total-price-wrap">
              <div class="qty-wrap">
                <div class="select-item">
                  <div class="btn-group" role="group">
                    <button class="btn btn-outline-primary" @click="down">-</button>
                    <input type="text" disabled v-model="itemCount">
                    <button class="btn btn-outline-primary" @click="up">+</button>
                  </div>
                </div>
              </div>
              <div>
                <span>합계</span>
                <span class="total-price">{{
                    totalSalePrice + totalMandatorySalePrice + totalOptionalSalePrice | makeComma
                  }}원</span>
              </div>
            </div>
            <div class="order-btn-wrap">
              <!--              <button @click="addCart" class="btn btn-xl btn-outline-primary">장바구니</button>
                            <button style="display:none" class="btn btn-xl btn-outline-primary" data-bs-toggle="modal"
                                    data-bs-target="#cartView" ref="cartConfirm"></button>-->
              <button @click="subscribeNow" class="btn btn-xl btn-primary">구독하기</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="detail-content">
      <h3 class="product-detail-title">상세정보</h3>
      <div class="product-detail">
        <span v-html="itemDetail.description"></span>
      </div>
    </div>
  </div>
  <Footer></Footer>
  <!-- 장바구니 확인 모달 -->
  <div class="modal mons-modal" id="cartView" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <div class="subs-cancel-cont">
            <span class="pt-3">상품이 장바구니에 담겼습니다.</span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal" ref="cartModalClose">창닫기</button>
          <button class="btn btn-primary" @click="goCart">장바구니 보기</button>
        </div>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'

export default {
  name: 'ItemDetail',
  metaInfo() {
    return {
      title: this.itemName,
      keywords: this.keywords,
      meta: [
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.itemName },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: location.href }
      ]
    }
  },
  components: {
    Footer, Navi
  },
  computed: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    })
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      itemId: this.$route.query.itemId,
      itemName: null,
      keywords: null,
      selectedTab: 'itemInfo',
      itemDetail: null,
      itemLargeImages: [],
      mandatoryUsableItemOptions: [], // 상품의 필수 옵션들
      optionalUsableItemOptions: [], // 상품의 선택 옵션들
      selectedMandatoryItemOptions: [], // 사용자가 선택한 필수 옵션들
      selectedOptionalItemOptions: [], // 사용자가 선택한 선택 옵션들
      mandatoryPriceOption: false, // 필수 옵션들 중에 가격이 있는지 여부. 가격이 있는 필수 옵션이 상품가로 대체.
      mandatoryItemCount: 0, // 필수 옵션들이 있을 때 사용자가 구매하려는 총 수량
      itemCount: 1, // 필수 옵션 없을 때 사용자가 구매하려는 총 수량
      totalSalePrice: 0,
      totalMandatorySalePrice: 0,
      totalOptionalSalePrice: 0,
      cartDTO: {
        userId: null
      },
      orderDTO: {
        orderId: '',
        orderStatus: 'Ready',
        orderType: 'FIRST_SUBSCRIPTION',
        items: [],
        backUrl: '/itemDetail?itemId=' + this.$route.query.itemId
      }
    }
  },
  created() {
    this.getItem()
  },
  methods: {
    ...mapActions({
      setOrderDTO: 'orderStore/setOrderDTO',
      setSubsSelectInfo: 'orderStore/setSubsSelectInfo',
      setSubsOrderItems: 'orderStore/setSubsOrderItems',
      removeRepeatOrder: 'orderStore/removeRepeatOrder'
    }),
    getItem() {
      axios.get('/api/item/' + this.itemId).then(({ data }) => {
        this.itemName = data.result.name
        this.keywords = data.result.keywords
        this.itemDetail = data.result
        console.log(this.itemDetail)
        for (let i = 0; i < this.itemDetail.itemImages.length; i++) {
          if (this.itemDetail.itemImages[i].itemImageType === 'L') {
            this.itemLargeImages.push(this.itemDetail.itemImages[i])
          }
        }

        // 사용하는 옵션 선별하고 필수옵션과 선택옵션으로 구분
        for (let i = 0; i < this.itemDetail.itemOptions.length; i++) {
          if (this.itemDetail.itemOptions[i].isUse === 'Y') {
            this.itemDetail.itemOptions[i].selectedOptionDetailId = ''
            this.itemDetail.itemOptions[i].optionDetailText = ''
            const cloneItemOptions = JSON.parse(JSON.stringify(this.itemDetail.itemOptions[i]))
            if (this.itemDetail.itemOptions[i].isRequired === 'Y') {
              if (this.itemDetail.itemOptions[i].hasPrice === 'Y') {
                this.mandatoryPriceOption = true // 필수옵션중에 가격이 있으면 해당 옵션가격이 상품가. 없으면 상품정보에 가격이 상품가.
              }
              this.mandatoryUsableItemOptions.push(cloneItemOptions)
            } else {
              this.optionalUsableItemOptions.push(cloneItemOptions)
            }
          }
        }

       /* if (this.mandatoryUsableItemOptions.length === 0) {
          this.totalSalePrice = this.itemDetail.salePrice // 상품에 필수 옵션이 없으면 상품정보의 가격이 상품가
        } else if (!this.mandatoryPriceOption) { // 필수옵션중에 가격있는 옵션이 없는 경우 필수옵션 선택이후에 총금액 생성을 위해 상품가 0원 표시
          this.totalSalePrice = 0
        } */
        this.totalSalePrice = this.itemDetail.salePrice
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
        this.$router.push('/items')
      })
    },
    mandatoryOptionSelect(event, option) {
      let flag = true

      for (let i = 0; i < this.mandatoryUsableItemOptions.length; i++) {
        if (this.mandatoryUsableItemOptions[i].optionInputType === 'SELECT' && this.mandatoryUsableItemOptions[i].selectedOptionDetailId === '') {
          flag = false
        }
        if (this.mandatoryUsableItemOptions[i].optionInputType === 'TEXT' && this.mandatoryUsableItemOptions[i].optionDetailText === '') {
          flag = false
        }
      }
      if (flag) {
        const selectedItem = {}
        selectedItem.itemId = this.itemId
        selectedItem.itemCount = 1
        selectedItem.itemOptions = []
        let temp = '' // 필수 옵션 중복 확인을 위한 optionDetailId들 저장
        for (let i = 0; i < this.mandatoryUsableItemOptions.length; i++) {
          const selectedOption = {}
          if (this.mandatoryUsableItemOptions[i].optionInputType === 'TEXT') {
            selectedOption.itemOptionDetailId = this.mandatoryUsableItemOptions[i].itemOptionDetails[0].optionDetailId // optionInputType이 TEXT일때는 option 파라메터에 optionDetailId 셋팅
            selectedOption.itemOptionId = this.mandatoryUsableItemOptions[i].itemOptionId
            selectedOption.optionTextValue = this.mandatoryUsableItemOptions[i].optionDetailText
          } else {
            selectedOption.itemOptionDetailId = this.mandatoryUsableItemOptions[i].selectedOptionDetailId
            selectedOption.itemOptionId = this.mandatoryUsableItemOptions[i].itemOptionId
          }

          selectedOption.optionCount = 1
          selectedOption.optionName = this.mandatoryUsableItemOptions[i].optionName
          selectedOption.hasPrice = this.mandatoryUsableItemOptions[i].hasPrice

          const tempOptionDetail = this.mandatoryUsableItemOptions[i].itemOptionDetails.find(detail => detail.optionDetailId === selectedOption.itemOptionDetailId)

          if (selectedOption.hasPrice === 'Y') {
            selectedOption.optionDetailSalePrice = tempOptionDetail.optionDetailSalePrice
          } else {
            selectedOption.optionDetailSalePrice = 0
          }
          selectedOption.optionDetailTitle = tempOptionDetail.optionDetailTitle

          temp += this.mandatoryUsableItemOptions[i].selectedOptionDetailId
          selectedItem.itemOptions.push(selectedOption)

          this.mandatoryUsableItemOptions[i].selectedOptionDetailId = ''
          this.mandatoryUsableItemOptions[i].optionDetailText = ''
        }
        if (this.selectedMandatoryItemOptions.length > 0) {
          const tempOption = this.selectedMandatoryItemOptions.find(it => it.selectedMandatory === temp)
          if (tempOption) {
            alert('이미 선택된 옵션입니다.')
            return
          }
        }
        selectedItem.selectedMandatory = temp
        this.selectedMandatoryItemOptions.push(selectedItem)
      }
    },
    optionalOptionSelect(event, option) {
      if (!event.target.value) {
        return
      }
      if (this.selectedOptionalItemOptions.length > 0) {
        const temp = this.selectedOptionalItemOptions.find(it => it.itemOptionDetailId === event.target.value)
        if (temp) {
          alert('이미 선택된 옵션입니다.')
          return
        }
      }
      const selectedOption = {}
      selectedOption.itemOptionDetailId = event.target.value
      selectedOption.itemOptionId = option.itemOptionId
      selectedOption.optionName = option.optionName
      selectedOption.hasPrice = option.hasPrice

      // eslint-disable-next-line eqeqeq
      const tempOptionDetail = option.itemOptionDetails.find(detail => detail.optionDetailId == selectedOption.itemOptionDetailId)

      selectedOption.optionDetailTitle = tempOptionDetail.optionDetailTitle
      if (selectedOption.hasPrice === 'Y') {
        selectedOption.optionDetailSalePrice = tempOptionDetail.optionDetailSalePrice
      } else {
        selectedOption.optionDetailSalePrice = 0
      }
      selectedOption.optionCount = 1
      this.selectedOptionalItemOptions.push(selectedOption)
    },
    up() {
      this.itemCount += 1
      this.totalSalePrice = this.itemDetail.salePrice * this.itemCount
    },
    down() {
      if (this.itemCount < 2) {
        return
      }
      this.itemCount -= 1
      this.totalSalePrice = this.itemDetail.salePrice * this.itemCount
    },
    upMandatory(mandatoryOrderItem) {
      mandatoryOrderItem.itemCount += 1

      for (let i = 0; i < mandatoryOrderItem.itemOptions.length; i++) {
        mandatoryOrderItem.itemOptions[i].optionCount = mandatoryOrderItem.itemCount
      }
    },
    downMandatory(mandatoryOrderItem) {
      if (mandatoryOrderItem.itemCount < 2) {
        return
      }
      mandatoryOrderItem.itemCount -= 1

      for (let i = 0; i < mandatoryOrderItem.itemOptions.length; i++) {
        mandatoryOrderItem.itemOptions[i].optionCount = mandatoryOrderItem.itemCount
      }
    },
    upOptional(optionalOption) {
      optionalOption.optionCount += 1
    },
    downOptional(optionalOption) {
      if (optionalOption.optionCount < 2) {
        return
      }
      optionalOption.optionCount -= 1
    },
    removeOrderItem(index) {
      this.selectedMandatoryItemOptions.splice(index, 1)
    },
    removeOptionalItem(index) {
      this.selectedOptionalItemOptions.splice(index, 1)
    },
    byeNow() {
      if (!this.isLogin) {
        this.$router.push('/login?redirect=/itemDetail?itemId=' + this.itemId)
        return
      }

      axios.post('/api/order/initOrder', this.orderDTO).then(({ data }) => {
        console.log(data)
        this.orderDTO.orderId = data.result.orderId
        this.orderDTO.orderTitle = data.result.orderTitle
        this.orderDTO.totalAmount = data.result.totalAmount
        this.orderDTO.totalSaleAmount = data.result.totalSaleAmount
        this.orderDTO.userId = data.result.user.userId
        this.setOrderDTO(this.orderDTO)
        this.$router.push('/itemPayment')
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    subscribeNow() {
      if (!this.isLogin) {
        this.$router.push('/login?redirect=/itemDetail?itemId=' + this.itemId)
      }

      const tempItem = {}
      tempItem.itemId = this.itemId
      tempItem.itemCount = this.itemCount
      tempItem.itemName = this.itemDetail.name
      tempItem.itemPrice = this.itemDetail.price
      tempItem.itemSalePrice = this.itemDetail.salePrice
      tempItem.itemSubsSalePrice = this.itemDetail.subsSalePrice
      tempItem.itemSmallImage = this.smallImage(this.itemDetail)

      this.orderDTO.items.push(tempItem)

      this.removeRepeatOrder()
      this.setOrderDTO(this.orderDTO)
      this.$router.push({ name: 'SubscriptionOrderConfirm' })
    },
    smallImage(item) {
      for (let i = 0; i < item.itemImages.length; i++) {
        if (item.itemImages[i].itemImageType === 'S') {
          return this.gcpUrl + item.itemImages[i].cloudPath
        }
      }
    },
    addCart() {
      if (this.mandatoryUsableItemOptions.length === 0) {
        const tempItem = {}
        tempItem.itemId = this.itemId
        tempItem.itemCount = this.itemCount
        tempItem.itemName = this.itemDetail.name
        tempItem.itemPrice = this.itemDetail.originSalePrice
        tempItem.itemSalePrice = this.itemDetail.salePrice
        tempItem.itemSubsSalePrice = this.itemDetail.subsSalePrice
        tempItem.itemSmallImage = this.smallImage(this.itemDetail)

        if (this.selectedOptionalItemOptions.length > 0) {
          tempItem.itemOptions = []
          for (let i = 0; i < this.selectedOptionalItemOptions.length; i++) {
            tempItem.itemOptions.push(this.selectedOptionalItemOptions[i])
          }
        }
        this.orderDTO.items.push(tempItem)
      } else {
        if (this.selectedMandatoryItemOptions.length < 1) {
          alert('필수 옵션을 선택해주세요.')
          return
        }
        for (let i = 0; i < this.selectedMandatoryItemOptions.length; i++) {
          this.orderDTO.items.push(this.selectedMandatoryItemOptions[i])
        }
        this.orderDTO.selectedMandatoryItemOptionsJson = JSON.stringify(this.selectedMandatoryItemOptions)
        if (this.selectedOptionalItemOptions.length > 0) {
          for (let i = 0; i < this.selectedOptionalItemOptions.length; i++) {
            this.orderDTO.items[0].itemOptions.push(this.selectedOptionalItemOptions[i])
          }
        }
      }

      this.orderDTO.selectedOptionalItemOptionsJson = JSON.stringify(this.selectedOptionalItemOptions)

      if (!this.isLogin) {
        const tempuid = localStorage.getItem('tempuid')

        if (tempuid) {
          this.orderDTO.tempUserId = tempuid
        } else {
          this.orderDTO.tempUserId = this.uuidGen()
          localStorage.setItem('tempuid', this.orderDTO.tempUserId)
        }
      }

      axios.post('/api/cart/addCart', this.orderDTO).then(({ data }) => {
        console.log(data)
        this.$refs.cartConfirm.click()
        // this.$router.push('/cart')
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    isFirst(index) {
      if (index === 0) return true
    },
    goCart() {
      this.$refs.cartModalClose.click()
      this.$router.push('/cart')
    },
    uuidGen() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    isActive(tab) {
      if (this.selectedTab === tab) {
        return true
      }
    }
  },
  watch: {
    selectedMandatoryItemOptions: {
      deep: true,
      handler() {
        let tempTotalAmount = 0
        let tempMandatoryItemCount = 0
        for (let i = 0; i < this.selectedMandatoryItemOptions.length; i++) {
          if (this.mandatoryPriceOption) {
            for (let j = 0; j < this.selectedMandatoryItemOptions[i].itemOptions.length; j++) {
              if (this.selectedMandatoryItemOptions[i].itemOptions[j].hasPrice === 'Y') {
                tempTotalAmount += this.selectedMandatoryItemOptions[i].itemOptions[j].optionDetailSalePrice * this.selectedMandatoryItemOptions[i].itemOptions[j].optionCount
              }
            }
          } else {
            tempTotalAmount += this.selectedMandatoryItemOptions[i].itemCount * this.itemDetail.salePrice
          }
          tempMandatoryItemCount += this.selectedMandatoryItemOptions[i].itemCount
        }

        this.totalMandatorySalePrice = tempTotalAmount
        this.mandatoryItemCount = tempMandatoryItemCount
      }
    },
    selectedOptionalItemOptions: {
      deep: true,
      handler() {
        let tempTotalAmount = 0
        for (let i = 0; i < this.selectedOptionalItemOptions.length; i++) {
          if (this.selectedOptionalItemOptions[i].hasPrice === 'Y') {
            tempTotalAmount += this.selectedOptionalItemOptions[i].optionDetailSalePrice * this.selectedOptionalItemOptions[i].optionCount
          }
        }
        this.totalOptionalSalePrice = tempTotalAmount
      }
    }
  }
}
</script>

<style scoped>

</style>

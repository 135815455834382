<template>
  <body class="d-flex flex-column h-100">
  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2 border-b">
          <h2>회원탈퇴</h2>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="my-info-wrap">
              <span class="stitle">탈퇴 사유를 선택해주세요</span>
              <ul class="dropout-sel">
                <li>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="dropout" id="reason1" :value="reason[0]"
                           v-model="withdrawForm.withdrawReason">
                    <label class="form-check-label" for="reason1">
                      {{reason[0]}}
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="dropout" id="reason2" :value="reason[1]"
                           v-model="withdrawForm.withdrawReason">
                    <label class="form-check-label" for="reason2">
                      {{reason[1]}}
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="dropout" id="reason3" :value="reason[2]"
                           v-model="withdrawForm.withdrawReason">
                    <label class="form-check-label" for="reason3">
                      {{reason[2]}}
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="dropout" id="reason4" :value="reason[3]"
                           v-model="withdrawForm.withdrawReason">
                    <label class="form-check-label" for="reason4">
                      {{reason[3]}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="my-info-wrap">
              <span>탈퇴 신청 전 아래 내용을 확인해주세요.</span>
              <ul class="dropout-info">
                <li>개인정보처리방침에 의거해 서비스 이용기록 등의 모든 정보가 삭제되고 복구할 수 없습니다.</li>
                <li>탈퇴 신청한 계정의 이메일로 즉시 재가입이 불가능합니다. 단 탈퇴일 이후 3개월이 지나면 가입할 수 있습니다.</li>
              </ul>
            </div>
            <div class="bottom-btn-wrap">
              <a href="/userModify" class="btn btn-sm btn-outline-primary">취소</a>
              <button class="btn btn-sm btn-outline-primary" @click="goWithdraw" >탈퇴하기</button>
              <button data-bs-toggle="modal" data-bs-target="#dropout-check" ref="withdrawButton" style="display: none"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  <!-- 회원탈퇴 모달 -->
  <div class="modal mons-modal" id="dropout-check" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">회원탈퇴</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="dropout-wrap">
            <span class="tt">그동안 Monscent를<br>이용해 주셔서 감사합니다.</span>
            <p class="ts">
              고객님께서 신청하신 회원탈퇴가<br>정상적으로 처리 되었습니다.<br><br>
              감사합니다.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="withdraw">확인</button>
          <button data-bs-dismiss="modal" ref="confirmClose" style="display: none"></button>
        </div>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer'
import { mapActions } from 'vuex'

const reason = ['제품의 향이 마음에 들지 않습니다.',
                '서비스 UI가 불편합니다.',
                '가격이 부담이 됩니다.',
                '고객 응대가 소홀합니다.']

export default {
  name: 'UserWithdraw',
  components: { Footer },
  data() {
    return {
      withdrawForm: {
        withdrawReason: null
      },
      reason
    }
  },
  methods: {
    ...mapActions({
      logout: 'userStore/logout'
    }),
    withdraw() {
      if (this.withdrawForm.withdrawReason === null) {
        alert('탈퇴사유를 선택해주세요.')
        return
      }
      axios.post('/api/mypage/withdraw', this.withdrawForm).then(({ data }) => {
        alert('회원 탈퇴되었습니다.')
        this.logout()
        this.$refs.confirmClose.click()
        this.$router.push('/')
      }).catch((error) => {
        console.log(error)
      })
    },
    goWithdraw() {
      if (this.withdrawForm.withdrawReason === null) {
        alert('탈퇴사유를 선택해주세요.')
        return
      }
       this.$refs.withdrawButton.click()
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>

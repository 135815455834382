<template>
  <div class="modal mons-modal" id="return-order" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">교환, 반품 신청서</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-cont">
            <span class="title">신청구분</span>
            <div class="type-select">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="return-select" value="ex" v-model="type">
                <label class="form-check-label" for="item-change">교환신청</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="return-select" value="re" v-model="type" >
                <label class="form-check-label" for="item-return">반품신청</label>
              </div>
            </div>
            <div class="type-select">
              <span class="title">사유선택</span>
              <select class="form-select" v-model="selectedExchangeType" v-if="type === 'ex'">
                <option :value="null">교환사유를 선택해주세요.</option>
                <option v-for="opt in exchangeType" :key="opt.code" :value="opt.codeName">{{opt.codeName}}</option>
              </select>
              <select class="form-select" v-model="selectedRefundType" v-if="type === 're'">
                <option :value="null">반품사유를 선택해주세요.</option>
                <option v-for="opt in refundType" :key="opt.code" :value="opt.codeName">{{opt.codeName}}</option>
              </select>
            </div>
            <span class="title">신청내용</span>
            <div class="reason-wrap">
              <textarea name="reason" class="text-area" rows="4" placeholder="신청하신 사유를 간략히 적어 주세요" v-model="desc"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal" ref="registerClose">취소</button>
          <button class="btn btn-primary" @click="createRequest">신청완료</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExchangeRefundRequestModal',
  props: {
    orderId: String
  },
  data() {
    return {
      exchangeType: [],
      refundType: [],
      type: 'ex',
      selectedExchangeType: null,
      selectedRefundType: null,
      desc: null
    }
  },
  created() {
    this.getCodes('ExchangeType')
    this.getCodes('RefundType')
  },
  methods: {
    getCodes(groupName) {
      axios.get('/api/order/codeList/' + groupName).then(({ data }) => {
        console.log(data.result)
        if (groupName === 'ExchangeType') {
          this.exchangeType = data.result
        } else {
          this.refundType = data.result
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    createRequest() {
      if (this.type === 'ex') {
        if (this.selectedExchangeType === null || this.selectedExchangeType === '') {
          alert('교환사유를 선택해주세요.')
          return
        }
        // eslint-disable-next-line no-new-object
        const exchangeDTO = new Object()
        exchangeDTO.orderId = this.orderId
        exchangeDTO.exchangeReason = this.selectedExchangeType
        exchangeDTO.exchangeDescription = this.desc
        axios.post('/api/order/exchangeRequest', exchangeDTO).then(({ data }) => {
          console.log(data.result)
          alert('교환신청이 완료 되었습니다.')
          this.$refs.registerClose.click()
          this.$parent.getOrder()
        }).catch((error) => {
          console.log(error)
          alert(error.response.data.message)
        })
      } else {
        if (this.selectedRefundType === null || this.selectedRefundType === '') {
          alert('반품사유를 선택해주세요.')
          return
        }
        // eslint-disable-next-line no-new-object
        const refundDTO = new Object()
        refundDTO.orderId = this.orderId
        refundDTO.refundReason = this.selectedRefundType
        refundDTO.refundDescription = this.desc
        axios.post('/api/order/refundRequest', refundDTO).then(({ data }) => {
          console.log(data.result)
          alert('반품신청이 완료 되었습니다.')
          this.$refs.registerClose.click()
          this.$parent.getOrder()
        }).catch((error) => {
          console.log(error)
          alert(error.response.data.message)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>

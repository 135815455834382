<template>
  <body class="d-flex flex-column h-100">
  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-lg">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-user-following"></i> 월간향 Monscent 회원가입</h2>
          <span>회원정보와 서비스 약관에 동의해 주세요</span>
        </div>
        <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <form @submit.prevent="submitForm">
              <div class="mb-3">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-envelope"></i></span>
                  <input class="form-control binput" type="email" name="emailAddress" v-model="form.emailAddress"
                         :class="{ 'is-invalid': submitted && $v.form.emailAddress.$error}" placeholder="이메일 주소">
                  <div v-if="submitted && !$v.form.emailAddress.required" id="validationID" class="invalid-feedback">
                    이메일주소를 확인해주세요.
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                  <input v-if="!isPasswordView" class="form-control binput" type="password" name="password"
                         v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="비밀번호를 입력하세요">
                  <input v-else class="form-control binput" type="text" name="password" v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="비밀번호를 입력하세요">
                  <div v-if="submitted && !$v.form.password.required" id="validationPASS" class="invalid-feedback">
                    비밀번호를 확인해주세요
                  </div>
                  <div class="invalid-feedback" v-if="!$v.form.password.minLength">
                    비밀번호는 {{ $v.form.password.$params.minLength.min }}자 이상으로 입력해주세요.
                  </div>
                </div>
                <div class="form-check pt-2">
                  <input type="checkbox" class="form-check-input" v-model="isPasswordView">
                  <label class="form-check-label">비밀번호 보기</label>
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-user"></i></span>
                  <input class="form-control binput" type="text" name="username" v-model="form.username"
                         :class="{ 'is-invalid': submitted && $v.form.username.$error}" placeholder="이름">
                  <div class="invalid-feedback" v-if="!$v.form.username.required">
                    이름을 입력해주세요.
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-calendar"></i></span>
                  <input class="form-control binput" type="text" name="birthday" v-model="form.birthday"
                         :class="{ 'is-invalid': submitted && $v.form.birthday.$error}" placeholder="생년월일(6자리)">
<!--                  <div class="invalid-feedback" v-if="!$v.form.birthday.required">
                    생년월일을 입력해주세요.
                  </div>-->
                  <div class="invalid-feedback" v-if="!$v.form.birthday.integer">
                    숫자만 입력해주세요.
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-screen-smartphone"></i></span>
                  <input class="form-control binput" type="tel" name="mobilePhone" v-model="form.mobilePhone"
                         :class="{ 'is-invalid': submitted && $v.form.mobilePhone.$error}"
                         placeholder="휴대폰번호(- 없이 입력해주세요)" @keyup="autoCompleteHypen(form.mobilePhone)">
                  <div class="invalid-feedback" v-if="!$v.form.mobilePhone.required">
                    휴대폰번호를 확인해주세요
                  </div>
                </div>
              </div>
              <div class="mb-3 agree-all">
                <span>Monscent 서비스 약관에 동의해 주세요</span>
                <div>
                  <label class="checkbox-lg">모두 동의 합니다.
                    <input type="checkbox" v-model="agree.allCheck" v-on:change="allCheck">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="mb-4 agree-list">
                <ul>
                  <li>
                    <label class="checkbox-lg">만 14세 이상 입니다.
                      <input type="checkbox" v-model="agree.ageOver">
                      <span class="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label class="checkbox-lg">[필수] 전자금융거래약관 동의
                      <input type="checkbox" v-model="agree.mandatory1" ref="mandatory1" value="true">
                      <span class="checkmark"></span>
                    </label>
                    <a href="#terms1" data-bs-toggle="modal"><i class="icon-arrow-right"></i></a>
                  </li>
                  <li>
                    <label class="checkbox-lg">[필수] 개인정보 수집 및 이용 동의
                      <input type="checkbox" v-model="agree.mandatory2" ref="mandatory2" value="true">
                      <span class="checkmark"></span>
                    </label>
                    <a href="#terms2" data-bs-toggle="modal"><i class="icon-arrow-right" value="true"></i></a>
                  </li>
                  <li>
                    <label class="checkbox-lg">[선택] 마케팅 수신 동의
                      <input type="checkbox" v-model="agree.agreeMarketing" value="true">
                      <span class="checkmark"></span>
                    </label>
                    <a href="#terms3" data-bs-toggle="modal"><i class="icon-arrow-right" value="true"></i></a>
                  </li>
                </ul>
              </div>
              <div class="mb-3 d-grid gap-3">
                <button class="btn btn-xl btn-primary">가입하기</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  <!-- 전자금융거래약관 동의 모달 -->
  <div class="modal fade" id="terms1" tabindex="-1" aria-labelledby="terms1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">전자금융거래약관 동의</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
        </div>
        <div class="modal-body">
			<div class="terms-wrap">
				<span class="btit pb-4">[신용ㆍ체크카드 결제시]</span>
				<span class="btit">제1조(목적)</span>
				<span class="word">
					이 약관은 ㈜나인빌리언앤코 (이하 '회사'라 합니다)가 제공하는 전자지급결제대행서비스 및 결제대금예치서비스를 이용자가 이용함에 있 어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.
				</span>

				<span class="stit">제2조(용어의 정의)</span>
				<span class="word pb-3">
				이 약관에서 정하는 용어의 정의는 다음과 같습니다.
				</span>
				<ol class="word">
					<li class="pb-2">'전자금융거래'라 함은 회사가 전자적 장치를 통하여전자지급결제대행서비스및 결제대금예치서비스(이하 '전자금융거래 서비스'라 고 합니다)를 제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
					<li class="pb-2">'전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또 는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
					<li class="pb-2">'결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다)의 전부 또는 일부를 재화 또는 용역(이하 '재화 등'이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점 까지 결제대금을 예치하는 서비스를 말합니다.</li>
					<li class="pb-2">'이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.</li>
					<li class="pb-2">'접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), '전자서명법'상의 인증서, 회사에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합 니다.</li>
					<li class="pb-2">'거래지시'라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
					<li class="pb-2">'오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
				</ol>

				<span class="stit">제3조(약관의 명시 및 변경)</span>
				<ol class="word">
					<li class="pb-2">회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.</li>
					<li class="pb-2">회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.</li>
					<li class="pb-2">회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및 회사의 홈 페이지에 게시함으로써 이용자에게 공지합니다.</li>
					<li class="pb-2">회사는 제3항의 공지를 할 경우 "이용자가 변경에 따라 변경에 동의하지 아니한 경우 공지 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 해지의사표시를 하지 아니한 경우 동의한 것으로 본다."라는 내용을 통지합니다.</li>
				</ol>

				<span class="stit">제4조(전자지급결제대행서비스의 종류)</span>
				<span class="word pb-3">
				회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.
				</span>
				<ol class="word">
					<li class="pb-2">신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송, 수신하고 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
					<li class="pb-2">계좌이체대행서비스: 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여 원하는 계좌 로 이체할 수 있는 실시간 송금 서비스를 말합니다.</li>
					<li class="pb-2">가상계좌서비스: 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.</li>
					<li class="pb-2">기타: 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 '휴대폰 결제대행서비스', '상품권결제대행서비스' 등이 있습니다.</li>
				</ol>

				<span class="stit">제5조(결제대금예치서비스의 내용)</span>
				<ol class="word">
					<li class="pb-2">이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본 조에서 같습니다)는 재화 등을 공급받은 사실을 재 화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.</li>
					<li class="pb-2">회사는 이용자로부터 재화 등을 공급받은 사실을 통보받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에 통신판매업자에게 결제 대금을 지급합니다.</li>
					<li class="pb-2">회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시 없이 그 공급받은 사실을 회사에 통보하지 아니하 는 경우에는 이용자의 동의 없이 통신판매업자에게 결제대금을 지급할 수 있습니다.</li>
					<li class="pb-2">회사는 통신판매업자에게 결제대금을 지급하기 전에 이용자에게 결제대금을 환급 받을 사유가 발생한 경우에는 그 결제대금을 소비자에 게 환급합니다.</li>
					<li class="pb-2">회사는 이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리, 의무를 정하기 위하여 본 약관과는 별도로 결제대금예치서비스 이용약관을 제정할 수 있습니다.</li>
				</ol>

				<span class="stit">제6조(이용시간)</span>
				<ol class="word">
					<li class="pb-2">회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.</li>
					<li class="pb-2">회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경 우, 서비스 중단 3일 전까지 게시 가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시 없이 서비스를 중단할 수 있습니다.</li>
				</ol>

				<span class="stit">제7조(접근매체의 선정과 사용 및 관리)</span>
				<ol class="word">
					<li class="pb-2">회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.</li>
					<li class="pb-2">이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.</li>
					<li class="pb-2">이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며,  접근매체의 도용이나 위조 또는 변조를 방지하기 위 하여 충분한 주의를 기울여야 합니다.</li>
					<li class="pb-2">회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에 게 발생한 손해를 배상할 책임이 있습니다.</li>
				</ol>

				<span class="stit">제8조(거래내용의 확인)</span>
				<ol class="word">
					<li class="pb-2">회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한 사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송 등의 방법으로 거래내용에 관한 서면을 교부합니다.</li>
					<li class="pb-2">회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는 정보, 거래일자, 전자 적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의 접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용 시 거래승인에 관한 기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 하되, 회사가 전자지급 결제대행서비스 제공의 대가로 수취한 수수료에 관한 사항은 제공하는 거래 내용에서 제외됩니다.</li>
					<li class="pb-2">이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.</li>
					<li class="pb-2">회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에 게 발생한 손해를 배상할 책임이 있습니다.<br><br>
						주소: 서울시 마포구 아현동 686 크레디트센터 8층 이메일 주소: nicepay@nicepay.co.kr<br>
						전화번호: 1661-0808 / 1661-7335<br>
						FAX: 02-312-3591
					</li>
				</ol>

				<span class="stit">제9조(오류의 정정 등)</span>
				<ol class="word">
					<li class="pb-2">이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.</li>
					<li class="pb-2">회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.</li>
				</ol>

				<span class="stit">제10조(회사의 책임)</span>
				<ol class="word">
					<li class="pb-2">접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만 이용자가 제7조 제2항에 위반하거나 제3자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자 가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.</li>
					<li class="pb-2">회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다. 다만 본 조 제1항 단서에 해당하거나 법인('중소기업기본법' 제2조 제2항에 의한 소기업을 제외합니다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.</li>
					<li class="pb-2">회사는 이용자로부터의 거래지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력적인 사 유로 처리 불가능하거나 지연된 경우로서 이용자에게 처리 불가능 또는 지연사유를 통지한 경우(금융기관 또는 결제수단 발행업체나 통 신판매업자가 통지한 경우를 포함합니다)에는 이용자에 대하여 이로 인한 책임을 지지 아니합니다.</li>
					<li class="pb-2">회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다.</li>
				</ol>

				<span class="stit">제11조(전자지급거래계약의 효력)</span>
				<ol class="word">
					<li class="pb-2">회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며,   전자지급거래에 관한 거래지시의 내용을 전송하여 지 급이 이루어지도록 합니다.</li>
					<li class="pb-2">회사는 이용자의 전자지급거래에 관한 거래지시에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.</li>
				</ol>

				<span class="stit">제12조(거래지시의 철회)</span>
				<ol class="word">
					<li class="pb-2">이용자는 전자지급거래에 관한 거래지시의 경우 지급의 효력이 발생하기 전까지 거래지시를 철회할 수 있습니다.</li>
					<li class="pb-2">전항의 지급의 효력이 발생 시점이란 (i) 전자자금이체의 경우에는 거래 지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금 융기관의 계좌 원장에 입금기록이 끝난 때 (ii) 그 밖의 전자지급수단으로 지급하는 경우에는 거래 지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때를 말합니다.</li>
					<li class="pb-2">이용자는 지급의 효력이 발생한 경우에는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법 또는 본 약관 제5조에서 정한 바에 따라 결제대금을 반환 받을 수 있습니다.</li>
				</ol>

				<span class="stit">제13조(전자지급결제대행 서비스 이용 기록의 생성 및 보존)</span>
				<ol class="word">
					<li class="pb-2">회사는 이용자가 전자금융거래의 내용을 추적,  검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.</li>
					<li class="pb-2">전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제8조 제2항에서 정한 바에 따릅니다.</li>
				</ol>

				<span class="stit">제14조(전자금융거래정보의 제공금지)</span>
				<span class="word">회사는 전자금융거래 서비스를 제공함에 있어서 취득한 이용자의 인적 사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정 보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 아니합니다.</span>

				<span class="stit">제15조(분쟁처리 및 분쟁조정)</span>
				<ol class="word">
					<li class="pb-2">이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자금융거래 서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.<br>
						담당자 : 양다우<br>
						연락처(전화번호, 전자우편주소): 02-1661-0808(1661-7335) / nicepay@nicepay.co.kr
					</li>
					<li class="pb-2">이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.</li>
					<li class="pb-2">이용자는 '금융감독기구의 설치 등에 관한 법률' 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 '소비자보호법' 제31조 제1항의 규정에 따른 소비자보호원에 회사의 전자금융거래 서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.</li>
				</ol>

				<span class="stit">제16조(회사의 안정성 확보 의무)</span>
				<span class="word">회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등 의 정보기술부문 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.</span>

				<span class="stit">제17조(약관 외 준칙 및 관할)</span>
				<ol class="word">
					<li class="pb-2">이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신 전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.</li>
					<li class="pb-2">회사와 이용자 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.</li>
				</ol>

				<span class="btit pb-4">[카카오페이 결제시]</span>
				<span class="btit">제1장 총칙</span>

				<span class="stit">제1조 (목적)</span>
				<span class="word">본 약관은 주식회사 카카오페이(이하‘회사’라합니다)가 제공하는 전자지급결제대행서비스, 선불전자지급수단의 발행 및 관리서비스, 전자고지 결제서비스(이하 통칭하여 ‘전자금융거래서비스’라합니다)를 회원이 이용함에 있어, 회사와 회원 간 권리, 의무 및 회원의 이용절차 등에 관한 사 항을 규정하는 것을 그 목적으로 합니다.</span>

				<span class="stit">제2조 (정의)</span>
				<span class="word pb-2">①본 약관에서 정한 용어의 정의는 아래와 같습니다.</span>
				<ol class="word">
					<li class="pb-2">‘전자금융거래’라함은 회사가 전자적 장치를 통하여 전자금융업무를 제공하고, 회원이 회사의 종사자와 직접 대면하거나 의사소통을 하 지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
					<li class="pb-2">‘전자지급수단’이라 함은 선불전자지급수단, 신용카드 등 전자금융거래법 제2조 제11호에서 정하는 전자적 방법에 따른 지급수단을 말 합니다.</li>
					<li class="pb-2">‘전자지급거래’라함은 자금을 주는 자(이하 ‘지급인’이라 합니다)가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자(이하 ‘수취 인’이라 합니다)에게 자금을 이동하게 하는 전자금융거래를 말합니다.</li>
					<li class="pb-2">‘전자적 장치’라함은 전자금융거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로서 현금자동지급기, 자동입출금기,
지급용단말기, 컴퓨터, 전화기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다.</li>
					<li class="pb-2">‘접근매체’라함은 전자금융거래에 있어서 거래지시를 하거나 회원 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또 는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함합니다), 전자서명법 상의 전자서명생성정보 및 인증서, 금융 기관 또는 전자금융업자에 등록된 회원번호, 회원의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.</li>
					<li class="pb-2">‘전자금융거래서비스’라함은 회사가 회원에게 제공하는 제4조 기재의 서비스를 말합니다.</li>
					<li class="pb-2">‘회원’이라 함은 본 약관 제2장과 제3장과 제4장 에서 달리 정한 경우를 제외하고는 본 약관에 동의하고 본 약관에 따라 회사가 제공하는 전자금융거래서비스를 이용하는 이용자를 말합니다.</li>
					<li class="pb-2">‘거래지시’라함은 회원이 본 약관에 따라 회사에게 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
					<li class="pb-2">‘오류’라함은 회원의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 회원의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
				</ol>
				<span class="word pb-2">② 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 전자금융거래법 등 관련 법령에 정한 바에 따릅니다.</span>

				<span class="stit">제3조 (약관의 명시, 설명 및 변경)</span>
				<span class="word pb-2">① 회사는 회원이 전자금융거래를 하기 전에 본 약관을 개별 서비스에 게시하고 본 약관의 중요한 내용을 확인할 수 있도록 합니다.</span>
				<span class="word pb-2">② 회사는 회원의 요청이 있는 경우 전자문서의 전송(전자우편을 이용한 전송을 포함합니다), 모사전송, 우편 또는 직접교부의 방식에 의하여 본 약관의 사본을 회원에게 교부합니다.</span>
				<span class="word pb-2">③ 회사가 본 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 금융거래정보 입력화면 또는 개별 서비스의 공지사항 게시판 등에 게 시함으로써 회원에게 공지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경한 때에는 변경된 약관을 홈페이지에 1개월 이상 게시하고 회원에게 전자우편 등을 통하여 사후 통지합니다.</span>
				<span class="word pb-2">④ 회사는 제3항의 공지나 통지를 할 경우 “회원이 변경에 동의하지 아니한 경우 공지나 통지를 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 본다.”라는 취지의 내용을 공지하거나 통지합니다.</span>
				<span class="word pb-2">⑤ 회원이 제4항의 공지나 통지를 받은 날로부터 30일 이내에 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 봅니다.</span>

				<span class="stit">제4조 (약관의 명시, 설명 및 변경)</span>
				<span class="word pb-2">① 전자금융거래서비스는 다음 각 호의 개별 서비스로 구성됩니다.</span>
				<ol class="word">
					<li>전자지급결제대행서비스</li>
					<li>선불전자지급수단 발행 및 관리서비스</li>
					<li>전자고지결제서비스</li>
				</ol>
				<span class="word pb-2">② 회사는 필요시 회원에게 사전 고지하고 전자금융거래서비스를 추가하거나 변경할 수 있습니다.</span>

				<span class="stit">제5조 (서비스 이용 시간)</span>
				<span class="word pb-2">① 회사는 회원에게 연중무휴 1일 24시간 전자금융거래서비스를 제공함을 원칙으로 합니다. 다만, 금융회사 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.</span>
				<span class="word pb-2">② 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융회사 기타 결제수단 발행업자의 사정에 의하여 전자금융거래서비스 중단이 불 가피한 경우, 전자금융거래서비스 중단 3일 전까지 게시 가능한 전자적 수단을 통하여 전자금융거래서비스 중단 사실을 게시한 후 전자금융거래 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시 없이 전자금융거래 서비스를 중단할 수 있습니다.</span>

				<span class="stit">제6조 (거래내용의 확인)</span>
				<span class="word pb-2">① 회사는 개별 서비스 내 회원 정보 조회 화면을 통하여 회원의 거래내용(회원의 오류정정 요구사실 및 처리 결과에 관한 사항을 포함합니다)을 확인할 수 있도록 하며, 회원이 거래내용에 대해 서면교부를 요청하는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송, 우편 또는 직접 교부의 방법으로 거래내용에 관한 서면을 교부합니다.</span>
				<span class="word pb-2">② 회사는 제1항에 따른 회원의 거래내용 서면교부 요청을 받은 경우 전자적 장치의 운영장애, 그 밖의 이유로 거래내용을 제공할 수 없는 때에는 즉시 회원에게 전자문서 전송(전자우편을 이용한 전송을 포함한다)의 방법으로 그러한 사유를 알려야 하며, 전자적 장치의 운영장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제1항의 거래내용에 관한 서면의 교부기간에 산입하지 아니합니다.</span>
				<span class="word pb-2">③ 제1항의 대상이 되는 거래내용 중 대상기간이 5년인 것은 다음 각호와 같습니다.</span>
				<ol class="word">
					<li>거래계좌의 명칭 또는 번호</li>
					<li>전자금융거래의 종류 및 금액</li>
					<li>전자금융거래상대방을 나타내는 정보</li>
					<li>전자금융거래일시</li>
					<li>전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보</li>
					<li>회사가 전자금융거래의 대가로 받은 수수료</li>
					<li>회원의 출금 동의에 관한 사항</li>
					<li>해당 전자금융거래와 관련한 전자적 장치의 접속기록</li>
					<li>전자금융거래의 신청 및 조건의 변경에 관한 사항</li>
					<li>건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록</li>
				</ol>
				<span class="word pb-2">④ 제1항의 대상이 되는 거래내용 중 대상기간이 1년인 것은 다음 각 호와 같습니다.</span>
				<ol class="word">
					<li>건당 거래금액이 1만원 이하인 전자금융거래에 관한 기록</li>
					<li>전자지급수단의 이용과 관련된 거래승인에 관한 기록</li>
					<li>회원의 오류정정 유구사실 및 처리결과에 관한 사항</li>
					<li>기타 금융위원회가 고시로 정한 사항</li>
				</ol>
				<span class="word pb-2">
					⑤ 회원이 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.<br>
					-주소 : 경기도 성남시 분당구 판교역로 152, 알파돔타워12층<br>
					-사이트 주소 : http://www.kakao.com/kakaopay<br>
					-전화번호 : 1644-7405
				</span>

				<span class="stit">제7조 (거래지시의 철회)</span>
				<span class="word pb-2">① 회원이 회사의 전자금융거래서비스를 이용하여 전자지급거래를 한 경우, 회원은 지급의 효력이 발생하기 전까지 본 약관에서 정한 바에 따라 제6조 제5항에 기재된 주소, 전화번호, 전자우편주소를 이용한 우편, 전화연락, 전자문서 전송의 방법으로 또는 개별 서비스 내 철회에 의한 방법으로 거래지시를 철회할 수 있습니다. 단, 각 서비스 별 거래지시 철회의 효력 발생시기는 본 약관 제17조, 제27조, 제 31조에서 정한 바에 따릅니다.</span>
				<span class="word pb-2">② 회원은 전자지급의 효력이 발생한 경우에 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법에 따라 결제대금 을 반환 받을 수 있습니다.</span>

				<span class="stit">제8조 (거래지시의 철회)</span>
				<span class="word pb-2">① 회원은 전자금융거래서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대해 그 정정을 요구할 수 있습니다.</span>
				<span class="word pb-2">② 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때 또는 스스로 전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날 또는 오류가 있음을 안 날부터 2주 이내에 그 결과를 회원에게 알려 드립니다.</span>

				<span class="stit">제9조 (전자금융거래 기록의 생성 및 보존)</span>
				<span class="word pb-2">① 회사는 회원이 이용한 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성 하여 보존합니다.</span>
				<span class="word pb-2">② 제1항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제6조 제3항 및 제4항에 따릅니다.</span>

				<span class="stit">제10조 (개인정보 보호)</span>
				<span class="word pb-2">① 회사는 전자금융거래서비스를 제공함에 있어서 취득한 회원의 인적사항, 회원의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 금융실명법 등 법령에 의하거나 회원의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무 상 목적 외에 사용하지 아니합니다.</span>
				<span class="word pb-2">② 회사는 회원이 안전하게 전자금융거래서비스를 이용할 수 있도록 회원의 개인정보보호를 위하여 개인정보취급방침을 운용합니다. 회사의 개 인정보취급방침은 회사의 홈페이지 또는 개별 서비스에 링크된 화면에서 확인할 수 있습니다.</span>

				<span class="stit">제11조 (회사의 책임)</span>
				<span class="word pb-2">① 회사는 다음 각호의 사고로 인하여 회원에게 손해가 발생한 경우에는 그 손해를 배상할 책임을 부담합니다.</span>
				<ol class="word">
					<li>접근매체의 위조나 변조로 발생한 사고</li>
					<li>계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고</li>
					<li>전자금융거래를 위한 전자적 장치 또는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호에 따른 정보통신망에 침입 하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고</li>
				</ol>
				<span class="word pb-2">② 회사는 제1항에도 불구하고 다음 각호의 경우에는 그 책임의 전부 또는 일부를 회원이 부담하게 할 수 있습니다.</span>
				<ol class="word">
					<li>회원이접근매체를제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우</li>
					<li>제3자가 권한 없이 회원의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 접근매체를 누 설하거나 노출 또는 방치한 경우</li>
					<li>법인(중소기업기본법제2조 제2항에 의한 소기업을 제외한다)인 회원에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안 절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우</li>
				</ol>
				<span class="word pb-2">③ 회사는 회원으로부터 거래지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 기타의 불가항력적인 사유로 처리 불가능하거나 지연 된 경우로서 회원에게 처리 불가능 또는 지연사유를 통지한 경우(금융기관 또는 결제수단발행업자나 통신판매업자가 통지한 경우를 포함합니다)에 는 회원에 대해 이로 인한 책임을 부담하지 않습니다.</span>

				<span class="stit">제12조 (분쟁처리 절차)</span>
				<span class="word pb-2">① 회원은 회사의 서비스 페이지 하단에 게시된 분쟁처리 담당자 또는 연락처를 통하여 전자금융거래와 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.</span>
				<span class="word pb-2">② 회원이 회사에 대하여 분쟁처리를 신청한 경우 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 회원에게 안내 합니다.</span>
				<span class="word pb-2">③ 회원은 회사의 분쟁처리결과에 대하여 이의가 있을 경우 금융위원회의 설치 등에 관한 법률 제51조의 규정에 따른 금융감독원의 금융분쟁조 정위원회나 소비자기본법 제60조 제1항의 규정에 따른 한국소비자원의 소비자분쟁조정위원회에 회사의 전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.</span>

				<span class="stit">제13조 (회사의 안전성 확보 의무)</span>
				<span class="word pb-2">회사는 전자금융거래가 안전하게 처리될 수 있도록 전자금융거래의 종류 별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.</span>

				<span class="stit">제14조 (약관 외 준칙)</span>
				<span class="word pb-2">① 회사와 회원 사이에 개별적으로 합의한 사항이 본 약관에 정한 사항과 다를 때에는 그 합의사항을 본 약관에 우선하여 적용합니다.</span>
				<span class="word pb-2">② 전자금융거래에 관하여 본 약관에 정하지 않은 사항은 개별약관이 정하는 바에 따릅니다.</span>
				<span class="word pb-2">③ 본 약관과 전자금융거래에 관한 개별약관에 정하지 아니한 사항(용어의 정의 포함)에 대하여는 다른 합의사항이 없으면 전자금융거래법, 여신 전문금융업법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관계 법령에서 정한 바에 따릅니다.</span>

				<span class="stit">제15조 (관할)</span>
				<span class="word pb-2">회사와 회원 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.</span>

				<span class="btit">제2장 전자지급결제대행 서비스</span>

				<span class="stit">제16조 (정의)</span>
				<span class="word pb-2">본 장에서 정하는 용어의 정의는 아래와 같습니다.</span>
				<span class="word pb-2">① ‘전자지급결제대행 서비스’라함은 전자적 방법으로 재화 또는 용역(이하 '재화 등'이라고 합니다)의 구매에 있어서 지급결제 정보를 송신하거 나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</span>
				<span class="word pb-2">② ‘회원’이라 함은 본 약관에 동의하고 회사가 제공하는 전자지급결제대행 서비스를 이용하는 자를 말합니다.</span>

				<span class="stit">제17조 (거래지시의 철회)</span>
				<span class="word pb-2">① 회원이 전자지급결제대행 서비스를 이용한 경우 회원은 거래 지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 또는 회 사의 계좌의 원장에의 입금기록 또는 전자적 장치에의 입력이 끝나기 전까지 거래지시를 철회할 수 있습니다.</span>
				<span class="word pb-2">② 회원이 전자지급결제대행 서비스를 이용한 경우 회원은 거래 지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 또는 회 사의 계좌의 원장에의 입금기록 또는 전자적 장치에의 입력이 끝나기 전까지 거래지시를 철회할 수 있습니다.</span>

				<span class="stit">제18조 (이용금액의 한도)</span>
				<span class="word pb-2">회사의 정책 및 결제업체(이동통신사,카드사 등)의 기준에 따라 회원의 결제수단 별 월 누적 결제액 및 결제한도가 제한될 수 있습니다.</span>

				<span class="stit">제19조 (접근매체의 관리)</span>
				<span class="word pb-2">① 회사는 전자지급결제대행 서비스 제공 시 접근매체를 선정하여 회원의 신원, 권한 및 거래지시의 내용 등을 확인합니다.</span>
				<span class="word pb-2">② 회원은 접근매체를 사용함에 있어서 다른 법률에 특별한 규정이 없는 한 다음 각 호의 행위를 하여서는 아니 됩니다.</span>
				<ol class="word">
					<li>접근매체를 양도하거나 양수하는 행위</li>
					<li>접근매체를 대여하거나 사용을 위임하는 행위</li>
					<li>접근매체를 질권 기타 담보 목적으로 하는 행위</li>
					<li>1호부터 3호까지의 행위를 알선하는 행위</li>
				</ol>
				<span class="word pb-2">③ 회원은 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충 분한 주의를 기울여야 합니다.</span>
				<span class="word pb-2">④ 회사는 회원으로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 회원에게 발생 한 손해를 배상할 책임이 있습니다.</span>

				<span class="btit">제3장 선불전자지급수단의 발행 및 관리</span>

				<span class="stit">제20조 (정의)</span>
				<span class="word pb-2">① ‘선불전자지급수단’이라 함은 회사가 운영하는 웹사이트 및 제휴사 서비스 등에서 재화 또는 용역(이하 ‘재화 등’이라고  합니다)을 구매할 수 있는 카카오머니 등과 같이 회사가 발행 당시 미리 회원에게 공지한 전자금융거래법 상 선불전자지급수단을 말합니다.</span>
				<span class="word pb-2">② ‘충전’이라 함은 선불전자지급수단의 일정액을 확보하기 위해 회사가 지정한 지불수단을 통해 선불전자지급수단을 구매하거나, 회사 또는 회 사가 지정한 제3자의 서비스 등(이하 ‘서비스 등’이라 합니다)에서의 활동을 통해 선불전자지급수단을 적립 받는 것을 말합니다.</span>
				<span class="word pb-2">③ ‘회원’이라 함은 본 약관에 동의하고 판매자로부터 재화 등을 구매하고 선불전자지급수단을 결제수단으로 하여 그 대가를 지급하는 자를 말합니다.</span>
				<span class="word pb-2">④ ‘판매자’라함은 선불전자지급수단에 의한 거래에 있어서 회원에게 재화 등을 판매하는 자를 말합니다.</span>

				<span class="stit">제21조 (접근매체의 관리)</span>
				<span class="word pb-2">① 회사는 회원으로부터 접근매체의 분실 또는 도난 등의 통지를 받기 전에 발생하는 선불전자지급수단에 저장된 금액에 대한 손해에 대하여는 책임지지 않습니다.</span>
				<span class="word pb-2">② 제2장 결제대행서비스 제19조 제1항 내지 제4항은 본 장에 준용합니다.</span>

				<span class="stit">제22조 (거래의 정지)</span>
				<span class="word pb-2">① 회사는 회원이 아래 각 호의 사유에 해당하는 경우 해당 회원에게 전자우편 또는 기타 유효한 수단을 통해 통보하고, 회원 자격을 박탈하거나 선불전자지급수단의 사용을 일시적으로 제한할 수 있습니다.</span>
				<ol class="word">
					<li>회원 가입시 등록한 개인정보의 전부 또는 일부가 허위임이 밝혀지는 경우</li>
					<li>타인의 명의나 개인정보를 도용하여 회원 가입 후 선불전자지급수단을 이용하는 경우</li>
					<li>타인의 결제정보 등을 도용하거나 부정한 행위로 거래를 하는 경우</li>
					<li>회원의 접근매체를 매매하거나 양도하는 경우</li>
					<li>결제수단을 제공하는 사업자가 지정한 방식으로 이용요금을 납부하지 않고 장기 연체하는 경우</li>
					<li>다른 회원의 선불전자지급수단 사용을 방해하거나, 회사의 선불전자지급수단 제공에 지장을 초래하는 경우</li>
					<li>회사가 정한 기간 내에 사용제한 사유를 해소하지 못한 경우</li>
					<li>기타 선불전자지급수단 관련한 법령을 위반하는 경우</li>
				</ol>
				<span class="word pb-2">② 제1항에 해당하는 회원은 해당 사유를 해소한 이후 회사의 승낙에 따라 선불전자지급수단을 다시 사용할 수 있습니다. 제23조 (선불전자지급수단의 충전)</span>

				<span class="stit">제23조 (선불전자지급수단의 충전)</span>
				<span class="word pb-2">① 회원은 계좌출금, 기타 회사가 정하는 지불수단으로 선불전자지급수단을 구매하거나, 서비스 등에서의 활동으로 회사 또는 회사의 제휴사로부 터 적립 받아 선불전자지급수단을 충전할 수 있습니다.</span>
				<span class="word pb-2">② 회사가 지정한 지불 수단을 통한 선불전자지급수단 구매는 제1항에서 규정하는 지불 수단별로 지정된 금액으로 충전을 할 수 있으며, 지불 수 단에 따라 자체 제한금액이 있을 수 있습니다.</span>

				<span class="stit">제24조 (선불전자지급수단의 이용)</span>
				<span class="word pb-2">① 회원은 회사가 정한 기한 및 이용방법에 따라 선불전자지급수단을 이용할 수 있으며, 회사는 그 구체적인 사항을 본 약관 또는 선불전자지급수 단 관련 서비스 페이지를 통해 공지합니다.</span>
				<span class="word pb-2">② 회원은 서비스 등에서 재화 등을 구매할 때 선불전자지급수단을 지불 수단으로 사용할 수 있습니다.</span>
				<span class="word pb-2">③ 선불전자지급수단은 재화 등의 구매완료 시점에 즉시 차감됩니다.</span>
				<span class="word pb-2">④ 회원이 선불전자지급수단을 사용하는 경우 서비스 등에서 적립 받은 무상의 선불전자지급수단(이하 ‘적립선불’이라고 합니다), 회원이 구매한 선불전자지급수단(이하 ‘구매선불’이라고 합니다)의 순서로 차감합니다.</span>
				<span class="word pb-2">⑤ 회원이 선불전자지급수단을 사용한 재화 등의 구매를 취소하는 경우 회사는 재화 등 구매시 사용한 선불전자지급수단을 재충전하는 것을 원 칙으로 합니다.</span>

				<span class="stit">제25조 (선불전자지급수단의 유효기간)</span>
				<span class="word pb-2">① 개별 선불전자지급수단을 마지막으로 이용한 날 로부터 일백이십(120)개월이 경과하는 동안 해당 선불전자지급수단을 한번도 적립하거나 사용하지 않은 경우 회원이 충전한 해당 선불전자지급수단은 자동소멸됩니다.</span>
				<span class="word pb-2">② 회사는 전항에 따른 선불전자지급수단의 자동소멸 전에 회원에게 소멸예정 사실을 전자우편 등의 방법으로 통지합니다.</span>
				<span class="word pb-2">③ 본 약관에 대한 동의를 철회하는 경우 적립선불은 소멸되어 환급이 불가능합니다.</span>

				<span class="stit">제26조 (선불전자지급수단의 환급)</span>
				<span class="word pb-2">① 회원은 보유 중인 선불전자지급수단의 환급을 회사에 요구할 경우 구매선불 전액을 환급 받을 수 있습니다. 다만, 회사로부터 모은 적립선불의 경우 환급 대상에서 제외됩니다.</span>
				<span class="word pb-2">② 다음 각 호의 경우에는 구매선불의 잔액 전부를 환급합니다.</span>
				<ol class="word">
					<li>천재지변 등의 사유로 서비스 등에서 재화 또는 용역을 제공하기 곤란하여 선불전자지급수단을 사용하지 못하게 된 경우</li>
					<li>선불전자지급수단의 결함으로 서비스 등에서 재화 또는 용역을 제공하지 못하는 경우</li>
					<li>회원이 구매선불 잔액의 환급을 요청하는 경우</li>
					<li>본 약관에 대한 동의를 철회하는 경우</li>
				</ol>
				<span class="word pb-2">③ 현금 환급은 회원으로부터 환급 신청이 접수된 후 회원의 입금사실을 확인한 시점에서 제칠(7)영업일 이내에 회원이 지정한 계좌로 현금 입금 됩니다.</span>
				<span class="word pb-2">④ 회원은 회사에서 정한 기한 및 이용방법에 따라 선불전자지급수단을 이용할 수 있으며, 회사는 환급 등 구체적인 사항을 개별 선불전자지급수 단의 고객센터 페이지를 통해 안내합니다.</span>

				<span class="stit">제27조 (거래지시의 철회)</span>
				<span class="word pb-2">회원이 선불전자지급수단을 이용하여 자금을 지급하는 경우 회원은 거래 지시된 금액의 정보가 수취인이 지정한 전자적 장치에 도달하기 전까지 거래지시를 철회할 수 있습니다.</span>

				<span class="stit">제28조 (금지사항)</span>
				<span class="word pb-2">회원은 선불전자지급수단을 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</span>

				<span class="stit">제29조 (이용금액의 한도)</span>
				<span class="word pb-2">① 회사는 선불전자지급수단에 대해 실지명의 당 최고 200만원을 그 보유 한도로 합니다. 단 보유 한도는 회사의 정책에 따라 감액될 수 있습니다.</span>
				<span class="word pb-2">② 제2장 결제대행서비스 제18조는 본 장에 준용합니다. 제4장 전자고지결제서비스</span>

				<span class="stit">제30조 (정의)</span>
				<span class="word pb-2">본 장에서 사용하는 용어의 정의는 다음과 같습니다.</span>
				<span class="word pb-2">① ‘전자고지결제서비스’라함은 회사의 모바일메신저(이하 ‘카카오톡’이라 합니다)를 근간으로 국세, 지방세, 공공요금, 각종지로요금의 고지,  수납, 정산, 수납대금이체 등을 편리하고 효율적으로 처리하기 위해 제공하는 시스템 및 서비스 일체를 말합니다.</span>
				<span class="word pb-2">② ‘빌러(고지기관)’라함은 각종 서비스를 이용한 대가에 대해 요금고지서를 ‘회원’에게 발송하는 자를 말한다.</span>
				<span class="word pb-2">③ ‘카톡청구서’라함은 ‘빌러’의요금고지서 대신 ‘카카오톡’ 기반에 모바일기기로 전송하는 전자적인 방식의 고지대행서비스를 말한다.</span>
				<span class="word pb-2">④ ‘회원’이라 함은 본 약관에 동의하고 요금고지서를 ‘카톡청구서’로받는자를 말한다.</span>

				<span class="stit">제31조 (거래지시의 철회)</span>
				<span class="word pb-2">회원이 전자고지결제서비스내 지급수단을 이용하여 자금을 지급하는 경우 거래지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관 또는 전자금융업자의 전자적 장치에 입력이 끝나기 전까지 거래지시를 철회할 수 있습니다.</span>

				<span class="stit">제32조 (접근매체의 관리)</span>
				<span class="word pb-2">① 제2장 결제대행서비스 제19조 제1항 내지 4항은 본 장에 준용합니다.</span>

				<span class="stit">&#60;부칙&#62;</span>
				<span class="word pb-2">본 약관은 2021년 6월 1일부터 적용됩니다.</span>

				<span class="stit">[나인빌리언앤코 전자금융거래 자동승인 약관(신용•체크카드 결제시)]</span>
				<span class="word pb-2">본 신청의 대상은 나인빌리언앤코 구독 서비스(정기결제)을 가입하는 고객에 한하여 적용됩니다. 본 신청과 관련하여 본인은 다음 금융거래정보 (카드사(은행)명, 카드번호)를 신용카드 등 자동승인을 신규 신청하는 때로부터 해지 신청할 때까지 상기 청구기관(청구기관의 결제대행업체를 포함함)에 제공하는 것에 대하여 「금융실명거래 및 비밀보장에 관한 법률」의 규정에 따라 동의합니다.</span>
				<ol class="word">
					<li>'자동결제'는 본 약관에 따른 결제서비스를 이용하여, 청구서서비스 내에서 정기적으로 발생하는 청구기관의 청구서 및 고지서의 이용요 금을카드자동 결제 또는 카카오페이머니 자동결제로 자동으로 납부할 수 있는 기능을 말합니다.</li>
					<li>카드자동결제 :회원이 카드의 결제정보를 1회 등록하여 카드사로부터 인증 및 승낙을 받은 이후 나인빌리언앤코로부터 상품 등을 구매 시 카카오페이 비밀번호 등의 입력 없이 자동으로 결제할 수 있는 서비스입니다.(단, 카드사의 요청 등 필요한 경우 추가 인증을 받을 수 있습니다.)</li>
					<li>카카오페이머니 자동결제 : 카카오페이가 정한 방식에 따라 카카오페이머니를 이용한 자동결제를 1회 신청하고, 이후 나인빌리언앤코로부터 상품 등을 구매시 카카오페이 비밀번호 등의 입력 없이 자동으로 결제할 수 있는 서비스입니다.(단, 회사 또는 카카오머니 계좌 연결 은행 등의 필요에 따라 추가 인증을 받을 수 있습니다.)</li>
					<li>회원은 카카오페이가 정한 결제관련 절차를 마친 후 청구서서비스에서 자동납부를 신청할 수 있으며, 카카오페이가 해당 신청을 승낙함으로써 자동납부 기능을 이용할 수 있습니다.</li>
					<li>자동납부가 가능한 경우는 카카오페이와 청구기관의 사정에 따라 달라질 수 있습니다. 자동납부를 이용하는 회원은 설정된 납부일에 정 상적인 납부가 이루어질 수 있도록 자동납부 결제 수단을 관리하여야 합니다. 회원의 귀책으로 정상적인 납부가 이루어지지 않는 경우 그 로 인해 발생하는 손해는 회원이 부담합니다.</li>
					<li>회원이 자동납부의 신청, 변경 및 해지를 요청하였더라도 청구기관의 정책이나 카카오페이의 청구서서비스 운영정책에 따라서 요청 사항 은 일정 기간 이후부터 적용될 수 있습니다.</li>
					<li>회원은 카드 자동결제에서 사용하는 신용카드 결제정보, 카카오페이머니 결제 및 카카오페이머니 자동결제에서 사용하는 계좌 결제정보 그리고 카카오페이 비밀번호 등에 대한 관리책임이 있으며, 이를 제3자에게 제공, 누설하거나 이와 유사한 행위를 하여서는 안됩니다.</li>
					<li>회원은 언제든지 이용계약 해지 신청을 할 수 있으며, 카카오페이는 관련법령 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다. 단, 카드 자동결제와 카카오페이머니 자동결제의 경우 회원은 먼저 카드 자동결제와 카카오페이머니 자동결제가 연결되어 있는 나인빌리언앤코의 계정에서 자동 결제 등록을 해지하여야 합니다. “나인빌리언앤코 홈페이지의 마이페이지 결제 수단 관리”에서 결제수단을 변경하여 해지할 수 있습니다.</li>
				</ol>
			</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 개인정보 모달 -->
  <div class="modal fade" id="terms2" tabindex="-1" aria-labelledby="terms2" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">개인정보 수집 및 이용 동의</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
        </div>
        <div class="modal-body">
			<div class="terms-wrap">
				<span class="word">
				&#60;(주)나인빌리언앤코&#62;('https://www.monscent.com'이하 'Monscent(먼센트)')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br><br>
					○ 이 개인정보처리방침은 2021년 7월 1부터 적용됩니다.
				</span>
				<span class="stit">1. 개인정보 수집목적 및 이용목적</span>
				<ol class="word pb-2">
					<li>
						서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br>
						콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 , 금융거래 본인 인증 및 금융 서비스
					</li>
					<li>회원 관리<br>
					회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리 , 고지사항 전달
					</li>
				</ol>

				<span class="stit">2. 수집하는 개인정보 항목</span>
				<span class="word">
				로그인ID, 비밀번호, 주소, 휴대전화번호, 이메일
				</span>

				<span class="stit">3. 개인정보의 보유기간 및 이용기간</span>
				<span class="word">
				원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</span>
				<ol class="word">
					<li>
						가. 회사 내부 방침에 의한 정보 보유 사유<br>
						<ul>
							<li>부정거래 방지 및 운영방침에 따른 보관 : 5년</li>
						</ul>
					</li>
					<li>
						나. 관련 법령에 의한 정보보유 사유<br>
						<ul>
							<li>
								계약 또는 청약철회 등에 관한 기록<br>
								o 보존이유 : 전자상거래등에서의소비자보호에관한법률
							</li>
							<li>
								대금 결제 및 재화 등의 공급에 관한 기록<br>
								o 보존이유: 전자상거래등에서의소비자보호에관한법률<br>
								o 보존기간 : 5년
							</li>
							<li>
								소비자 불만 또는 분쟁처리에 관한 기록<br>
								o 보존이유 : 전자상거래등에서의소비자보호에관한법률<br>
								o 보존기간 : 3년
							</li>
							<li>
								로그 기록<br>
								o 보존이유: 통신비밀보호법<br>
								o 보존기간 : 3개월
							</li>
						</ul>
					</li>
				</ol>
				<span class="word pb-2">※ 동의를 거부할 수 있으나 거부시 회원 가입이 불가능합니다.</span>
			</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>

  <!-- 마케팅수신동의 모달 -->
  <div class="modal fade" id="terms3" tabindex="-1" aria-labelledby="terms3" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">마케팅 수신 동의</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
        </div>
        <div class="modal-body">
			<div class="terms-wrap">
				<span class="stit">1. 광고성 정보의 이용목적</span>
        <span class="word pb-2">
          Monscent(먼센트)가 제공하는 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
        </span>
        <span class="word pb-2">
          - 마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도 Monscent(먼센트)가 제공하는 서비스의 이용에 제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
        </span>

				<span class="stit">2. 미동의 시 불이익 사항</span>
        <span class="word pb-2">
        개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
        </span>

				<span class="stit">3. 서비스 정보 수신 동의 철회</span>
        <span class="word pb-2">
        Monscent(먼센트)에서 제공하는 마케팅 정보를 원하지 않을 경우 ‘ 마이페이지 > 회원 정보 수정’에서 철회를 요청할 수 있습니다. 또한 향후 마케팅 활용에 새롭게 동의하고자 하는 경우에는 ‘마이페이지 > 회원 정보 수정’에서 동의하실 수 있습니다.
        </span>

				<span class="word pt-4">시행일자 : 2021. 07. 01</span>
				<span class="stit">Monscent</span>
			</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import { email, integer, maxLength, minLength, required } from 'vuelidate/lib/validators'
import Footer from '@/components/Footer'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'RegisterPage',
  components: { Footer },
  data() {
    return {
      form: {
        username: null,
        emailAddress: null,
        password: null,
        birthday: null,
        mobilePhone: null,
        agreeMarketing: 'Y',
        ageOver: 'N',
        companyId: process.env.VUE_APP_COMPANY_ID
      },
      agree: {
        allCheck: false,
        ageOver: false,
        mandatory1: false,
        mandatory2: false,
        mandatory3: false,
        agreeMarketing: false
      },
      errorMessage: null,
      isPasswordView: false,
      submitted: false,
      userId: null,
      oauthUser: null
    }
  },
  created() {
    this.oauthUser = this.oauth2User()
    this.initInfo()
  },
  methods: {
    ...mapGetters({
      oauth2User: 'userStore/oauth2User'
    }),
    initInfo() {
      if (this.oauthUser !== null) {
        this.form.username = this.oauthUser.properties.nickname
        this.form.emailAddress = this.oauthUser.kakao_account.email
      }
    },
    submitForm() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.agree.mandatory1 !== true) {
        alert('전자금융거래약관에 동의해주세요.')
        this.$refs.mandatory1.focus()
        return
      }
      if (this.agree.mandatory2 !== true) {
        alert('개인정보 수집 및 이용에 동의해주세요.')
        this.$refs.mandatory2.focus()
        return
      }
      /* if (this.agree.mandatory3 !== true) {
        alert('전자금융거래약관에 동의해주세요.')
        this.$refs.mandatory3.focus()ㅞㅡ
        return
      } */

      this.form.agreeMarketing = this.agree.agreeMarketing ? 'Y' : 'N'
      this.form.ageOver = this.agree.ageOver ? 'Y' : 'N'

      axios.post('/api/user/registrations', this.form).then(({ data }) => {
        console.log(data)
        this.userId = data.userId
        this.$router.push({ name: 'RegisterComplete', params: { info: this.form.emailAddress } })
      }).catch((error) => {
        this.errorMessage = error.response.data.message
      })
    },
    allCheck() {
      if (this.agree.allCheck === true) {
        this.agree.ageOver = true
        this.agree.mandatory1 = true
        this.agree.mandatory2 = true
        this.agree.mandatory3 = true
        this.agree.agreeMarketing = true
      } else {
        this.agree.ageOver = false
        this.agree.mandatory1 = false
        this.agree.mandatory2 = false
        this.agree.mandatory3 = false
        this.agree.agreeMarketing = false
      }
    },
    back() {
      this.$router.go(-1)
    },
    autoCompleteHypen(val) {
      this.form.mobilePhone = this.getPhoneMask(val)
    },
    getPhoneMask(str) {
      str = str.replace(/[^0-9]/g, '')
      let tmp = ''
      if (str.length < 4) {
        return str
      } else if (str.length < 7) {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3)
        return tmp
      } else if (str.length < 11) {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3, 3)
        tmp += '-'
        tmp += str.substr(6)
        return tmp
      } else {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3, 4)
        tmp += '-'
        tmp += str.substr(7)
        return tmp
      }
    }
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50)
      },
      emailAddress: {
        required,
        email,
        maxLength: maxLength(100)
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30)
      },
      birthday: {
        required,
        integer,
        maxLength: maxLength(6),
        minLength: minLength(6)
      },
      mobilePhone: {
        required
      }
    }
  }
}
</script>

<style>
</style>

<template>
  <body class="d-flex flex-column h-100">
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap">
          <h2><i class="icon-lock-open"></i> LOGIN</h2>
        </div>
        <div class="flex-grow-1">
          <div class="abox-cont">
            <form @submit.prevent="submitForm">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="input-group has-validation">
                <span class="input-group-text"><i class="icon-envelope"></i></span>
                <input class="form-control binput" type="email" id="emailAddress" name="emailAddress" v-model="form.emailAddress"
                       :class="{ 'is-invalid': submitted && $v.form.emailAddress.$error}" placeholder="이메일 아이디를 입력하세요">
                <div v-if="submitted && !$v.form.emailAddress.required"  id="validationID" class="invalid-feedback">
                  이메일 주소를 확인해주세요
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                  <input v-if="!isPasswordView" class="form-control binput" type="password" name="password" v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="비밀번호를 입력하세요">
                  <input v-else class="form-control binput" type="text" name="password" v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="비밀번호를 입력하세요">
                  <div v-if="submitted && !$v.form.password.required" id="validationPASS" class="invalid-feedback">
                    비밀번호를 확인해주세요
                  </div>
                  <div class="invalid-feedback" v-if="!$v.form.password.minLength">
                    비밀번호는 {{$v.form.password.$params.minLength.min}}자 이상으로 입력해주세요.
                  </div>
                </div>
                <div class="form-check pt-3">
                  <input type="checkbox" class="form-check-input" v-model="isPasswordView">
                  <label class="form-check-label">비밀번호 보기</label>
                </div>
              </div>
              <div class="mb-3 d-grid">
                <button class="btn btn-xl btn-primary"><i class="icon-lock"></i> 로그인</button>
              </div>
              <div class="d-flex justify-content-center idpw">
                <a href="/findId">아이디 찾기</a>
                <a href="/passwordReset">비밀번호 찾기</a>
              </div>
            </form>
          </div>
          <div class="mb-4 d-grid">
            <button v-on:click="kakaoLogin" class="btn btn-xl btn-kakao"><i class="icon-katalk"></i> 카카오로 시작합니다.</button>
          </div>
          <div class="d-grid gap-4 abox-cont">
            <a href="/register" class="btn btn-xl btn-outline-primary"><i class="icon-user"></i> 회원가입</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'LoginPage',
  components: { Footer },
  data() {
    return {
      redirect: this.$route.query.redirect || '/home',
      form: {
        emailAddress: null,
        password: null,
        companyId: process.env.VUE_APP_COMPANY_ID
      },
      submitted: false,
      errorMessage: null,
      isPasswordView: false
    }
  },
  validations: {
    form: {
      emailAddress: {
        required, email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    }),
    kakaoLogin() {
      // eslint-disable-next-line no-undef
      Kakao.Auth.login({
        success: this.kakaoLoginSuccessHandler.bind(this),
        fail: function (err) {
          console.log(JSON.stringify(err))
        }
      })
    },
    kakaoLoginSuccessHandler: function (res) {
      this.$nextTick(function () {
        this.$store.dispatch('userStore/oauth2Login', res.access_token).then(() => {
          if (this.isLogin()) {
            this.$router.push(this.redirect)
          } else {
            this.$router.push({ name: 'RegisterPage' })
          }
        }).catch((error) => {
          alert(error)
          this.errorMessage = error.message
        })
      })
    },
    submitForm() {
      this.submitted = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.$store.dispatch('userStore/login', this.form).then(() => {
        this.$router.push(this.redirect)
      }).catch((error) => {
        alert(error)
        this.errorMessage = error.message
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="modal slide-right" id="add-adress" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog side-canvas">
      <div class="modal-content side-wrap">
        <div class="side-header">
          <h3>배송지 등록</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="side-body">
          <div class="adress-form-wrap">
            <form class="form-adress">
              <div class="row g-3">
                <div class="col-md-12">
                  <label class="visually-hidden">받는사람</label>
                  <input type="text" class="form-control" v-model="addressDTO.receiver" placeholder="받는사람">
                </div>
                <div class="col-5">
                  <button class="btn btn-primary w-100" type="button" @click="showApi">주소찾기</button>
                </div>
                <div class="col-7">
                  <label class="visually-hidden">우편번호</label>
                  <input type="text" class="form-control" v-model="addressDTO.zipCode" placeholder="우편번호">
                </div>
                <div id="wrap" ref="embed"
                     style="display:none;border:1px solid;width:500px;height:300px;margin:5px 0;position:relative">
                  <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap"
                       style="cursor:pointer;position:absolute;right:0;top:-1px;z-index:1"
                       @click="foldDaumPostcode" alt="접기 버튼">
                </div>
                <div class="col-12">
                  <label class="visually-hidden">주소</label>
                  <input type="text" class="form-control" v-model="addressDTO.address1" placeholder="">
                </div>
                <div class="col-12">
                  <label class="visually-hidden">상세주소</label>
                  <input type="text" class="form-control" v-model="addressDTO.address2" placeholder="상세주소">
                </div>

                <div class="col-4">
                  <select class="form-select" v-model="mobilePhone1">
                    <option>010</option>
                    <option>011</option>
                    <option>016</option>
                    <option>018</option>
                    <option>019</option>
                  </select>
                </div>
                <div class="col-4">
                  <input type="tel" class="form-control" v-model.number="mobilePhone2">
                </div>
                <div class="col-4">
                  <input type="tel" class="form-control" v-model.number="mobilePhone3">
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-grid side-btnwrap">
          <button @click="addDelivery" class="btn btn-xl btn-primary">등록하기</button>
          <button ref="close" data-bs-dismiss="modal"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeliveryModal',
  props: {
    type: String
  },
  data() {
    return {
      addressDTO: {
        zipCode: '',
        address1: '',
        address2: '',
        receiver: '',
        mobilePhone: ''
      },
      mobilePhone1: '010',
      mobilePhone2: '',
      mobilePhone3: '',
      setDefault: false
    }
  },
  created() {
  },
  methods: {
    addDelivery() {
      this.addressDTO.mobilePhone = this.mobilePhone1 + '-' + this.mobilePhone2 + '-' + this.mobilePhone3
      if (this.addressDTO.address1 === '' || this.addressDTO.address2 === '') {
        alert('주소를 입력해주세요')
        return
      }
      if (this.addressDTO.receiver === '') {
        alert('받는사람을 입력해주세요')
        return
      }
      if (this.setDefault === true) {
        axios.post('/api/user/setDefaultAddress', this.addressDTO).then(({ data }) => {
          console.log(data)
          this.$parent.getDefaultDelivery()
          this.$refs.close.click()
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$parent.modifyDelivery(this.addressDTO)
        this.$refs.close.click()
      }
    },
    foldDaumPostcode() {
      // eslint-disable-next-line camelcase
      const element_wrap = document.getElementById('wrap')
      element_wrap.style.display = 'none'
    },
    showApi() {
      // eslint-disable-next-line camelcase
      const element_wrap = document.getElementById('wrap')
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let addr // 주소 변수
          let extraAddr = '' // 참고항목 변수

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress
          } else { // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '') {
              extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== '') {
              extraAddr = ' (' + extraAddr + ')'
            }
          }
            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.addressDTO.zipCode = data.zonecode // 5자리 새우편번호 사용
            this.addressDTO.address1 = addr + extraAddr

            element_wrap.style.display = 'none'
          },
          onresize: function (size) {
            element_wrap.style.height = size.height + 'px'
          },
          width: '100%',
            height:
          '100%'
        }).embed(element_wrap)
      element_wrap.style.display = 'block'
    }
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from '@/store/index'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND
axios.defaults.headers.common.Accept = 'application/json'

Vue.prototype.$http = axios

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = token
}
axios.defaults.headers.common.Company = process.env.VUE_APP_COMPANY_ID

/* axios.interceptors.request.use(
  async function (config) {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
) */

// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom, i) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers.Authorization = token
          return axios(originalRequest)
        }).catch(err => {
          return Promise.reject(err)
        })
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = localStorage.getItem('refresh')
      return new Promise(function (resolve, reject) {
        axios.post('/api/refresh', { refreshToken: refreshToken }).then(({ data }) => {
          localStorage.setItem('token', data.token)
          // localStorage.setItem('refresh', data.refreshToken)
          axios.defaults.headers.common.Authorization = data.token
          originalRequest.headers.Authorization = data.token
          processQueue(null, data.token)
          resolve(axios(originalRequest))
        }).catch((err) => {
          store.dispatch('userStore/logout')
          router.push('/login')
          processQueue(err, null)
          reject(err)
        }).finally(() => {
          isRefreshing = false
        })
      })
    }
    return Promise.reject(error)
  }
)
/* axios.interceptors.response.use(
  response => response,
  async(error) => {
    const originalRequest = error.config
    const refreshToken = localStorage.getItem('refresh')
    if (error.response.status === 403 && !originalRequest.retry && refreshToken) {
      console.log('403 발생 ' + JSON.stringify(originalRequest))
      originalRequest.retry = true
      // localStorage.removeItem('token')
      // return axios(originalRequest)
      await store.dispatch('userStore/refresh')
      return axios(originalRequest);
    }
    return Promise.reject(error)
  }
) */

Vue.use(Vuelidate)
Vue.use(VueMoment)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.config.productionTip = false

Vue.filter('makeComma', val => {
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.use(VueGtag, {
  config: {
    id: 'G-S6SB4NTL07',
    params: {
      user_id: localStorage.getItem('GAUniqueId') || 0
    }
  }
}, router)
// this.$gtag.config({ user_id: localStorage.getItem('GAUniqueId') || 0 })

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <!-- 주문 프로세스 정보 모달 -->
  <div class="modal mons-modal" id="order-process" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">주문/배송 진행단계</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="process-header">
            <div class="icon-truck">
              <img src="images/icon-truck.png" width="50px" alt="truck icon">
            </div>
            <span class="tit">Monscent 배송 진행은 총 4단계로 진행이 되고 있습니다.</span>
          </div>
          <ul class="order-process">
            <li>
              <span class="tt">STEP 1<br>결제완료</span>
              <span class="ts">결제가 완료되어 주문이 접수 되었습니다.</span>
            </li>
            <li>
              <span class="tt">STEP 2<br>배송준비중</span>
              <span class="ts">고객님의 주문을 확인 하였습니다.</span>
            </li>
            <li>
              <span class="tt">STEP 3<br>배송중</span>
              <span class="ts">상품이 고객님께 배송중입니다.</span>
            </li>
            <li>
              <span class="tt">STEP 4<br>배송완료</span>
              <span class="ts">상품이 고객님께 배송 완료 되었습니다 :&#41;</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderProcessInfoModal'
}
</script>

<style scoped>

</style>

<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 max-limit">
    <div class="cont-wrapper">
      <LGnb :url="url"></LGnb>
      <div class="main-box" v-if="subscription">
        <section class="item-cont" v-if="!isEdit">
          <div class="sub-title-wrap">
            <h3 class="title">구독상품</h3>
            <div class="edit-wrap">
              <button @click="itemEdit(true)" class="btn btn-sm btn-primary">구독상품 수정</button>
            </div>
          </div>
          <ul class="item-wrap">
            <li class="item-goods" v-for="(subsItem, index) in subscriptionOrderItems" :key="index">
              <div class="item-thumb">
                <img :src="subsItem.itemSmallImage" alt="썸네일"/>
              </div>
              <div class="item-cont-wrap">
                <div class="item-info">
                  <span class="name">{{subsItem.itemName}}</span>
                  <span class="option"></span>
                </div>
                <div class="goods-price">
                  <div class="price-wrap">
                    <span class="qty">{{subsItem.itemCount}}개</span>
                    <span class="price">{{subsItem.itemSalePrice * subsItem.itemCount | makeComma}}원</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>

        <section class="item-cont" v-else>
          <div class="sub-title-wrap">
            <h3 class="title">구독상품</h3>
            <div class="edit-wrap">
              <button @click="itemEdit(false)" class="btn btn-sm btn-outline-primary">취소</button>
              <button class="btn btn-sm btn-primary" @click="modifySubsItems">수정완료</button>
            </div>
          </div>
          <ul class="item-wrap">
            <li class="item-goods" v-for="(subsItem, index) in editSubscriptionOrderItems" :key="index">
              <div class="item-thumb">
                <img :src="subsItem.itemSmallImage" alt="썸네일"/>
              </div>
              <div class="item-cont-wrap">
                <div class="item-info">
                  <span class="name">{{subsItem.itemName}}</span>
                </div>
                <div class="goods-price">
                  <div class="qty-wrap">
                    <div class="btn-group" role="group">
                      <button class="btn btn-sm btn-outline-primary" v-on:click="down(subsItem)">-</button>
                      <input type="text" disabled :value="subsItem.itemCount">
                      <button class="btn btn-sm btn-outline-primary" v-on:click="up(subsItem)">+</button>
                    </div>
                  </div>
                  <div class="price-wrap">
                    <span class="price">{{ subsItem.itemCount * subsItem.itemSalePrice | makeComma }}원</span>
                  </div>
<!--                  <div class="item-del">
                    <a href="#" class="btn-del" data-bs-toggle="modal" data-bs-target="#item-del">X 삭제</a>
                  </div>-->
                </div>
              </div>
            </li>
          </ul>
        </section>

        <section class="payment">
          <div class="sub-title-wrap">
            <h3 class="title">결제정보</h3>
          </div>
          <ul class="payment-info">
            <li>
              <span>상품금액</span>
              <span>{{ totalAmountCal() | makeComma }}원</span>
            </li>
            <li>
              <span>배 송 비</span>
              <span>0원</span>
            </li>
            <li>
              <span>할인금액</span>
              <span><i class="sale-badge">10%구독할인</i> -{{ totalAmountCal() - totalSaleAmountCal() | makeComma }}원</span>
            </li>
            <li>
              <span>결제 예정 금액</span>
              <span>{{ totalSaleAmountCal() | makeComma }}원</span>
            </li>
          </ul>
        </section>
        <section class="subscribe-setting">
          <div class="sub-title-wrap pt-4">
            <h3 class="title">구독설정</h3>
          </div>
          <ul class="payment-setting">
            <li>
              <span class="tt"><i class="icon-event"></i> 다음 결제 예정일</span>
              <div class="date-wrap">
                <span class="tx">{{ subscription.nextPaymentDay | moment("YYYY년 MM월 DD일") }} 결제 예정</span>
                <a href="#date-change" class="btn btn-primary" data-bs-toggle="collapse" aria-expanded="false"
                   aria-controls="date-change">예정일 변경하기</a>
                <div class="collapse" id="date-change">
                  <div class="date-box">
                    <div class="info-box">
                      캘린더에서 원하시는 다음 결제 예정일을 최대 3개월 안에서 변경 하실 수 있습니다.<br>
                      원하시는 날짜를 선택 하신 후 '변경확인'을 클릭하셔서 확정 해주세요.
                    </div>
                    <vDatePicker v-model="paymentDate" :model-config="modelConfig"
                                 :available-dates='{ start: new Date(new Date().setDate(new Date().getDate() + 1)), end: null }'/>
                    <div class="date-btn-wrap">
<!--                      <a href="#" class="btn btn-sm btn-outline-primary">기존날짜</a>-->
                      <button class="btn btn-sm btn-primary" @click="modifyNextPayment">변경확인</button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="tt"><i class="icon-reload"></i> 구독주기 변경</span>
              <div class="week-select-wrap">
                <div class="week-select">
                  <input type="radio" class="btn-check" id="4w" autocomplete="off" v-model="selectedPeriod"
                         value="4">
                  <label class="btn-week" for="4w" data-bs-toggle="modal" data-bs-target="#period-edit">4주 마다</label>

                  <input type="radio" class="btn-check" id="6w" autocomplete="off" v-model="selectedPeriod"
                         value="6">
                  <label class="btn-week" for="6w" data-bs-toggle="modal" data-bs-target="#period-edit">6주 마다</label>

                  <input type="radio" class="btn-check" id="8w" autocomplete="off" v-model="selectedPeriod"
                         value="8">
                  <label class="btn-week" for="8w" data-bs-toggle="modal" data-bs-target="#period-edit">8주 마다</label>

                  <input type="radio" class="btn-check" id="12w" autocomplete="off" v-model="selectedPeriod"
                         value="12">
                  <label class="btn-week" for="12w" data-bs-toggle="modal" data-bs-target="#period-edit">12주 마다</label>
                </div>
                <div class="next-week">
                  <div class="item">
                    <span>다음 결제 예정일</span>
                    <span class="date">{{ subscription.nextPaymentDay | moment("MM월 DD일") }}</span>
                  </div>
                  <div class="item">
                    <span>이후 결제 예정일</span>
                    <span class="date">{{ afterNextPaymentDay | moment("MM월 DD일") }}</span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="tt"><i class="icon-location-pin"></i> 배송지 정보</span>
              <ul class="delivery-info">
                <li class="name">{{ delivery4Repeat.receiver }}</li>
                <li class="adress">&#91;{{ delivery4Repeat.zipCode }}&#93; {{ delivery4Repeat.address1 }}
                  {{ delivery4Repeat.address2 }}
                </li>
                <li class="tel">{{ delivery4Repeat.mobilePhone }}</li>
                <li class="pt-1">
                  <button class="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                          data-bs-target="#add-adress">
                    <i class="icon-pencil"></i> 배송정보 수정
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <span class="tt"><i class="icon-ban"></i> 구독해지</span>
              <button class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#cancel-subs">구독
                해지하기</button>
            </li>
          </ul>
          <div class="back-page">
            <a href="/subscriptions" class="btn btn-primary"><i class="icon-arrow-left"></i> 리스트로 돌아가기</a>
          </div>
        </section>
      </div>
    </div>
  </div>
  <Footer></Footer>
  <DeliveryModal v-bind:type="deliveryModalType"></DeliveryModal>
  <!-- 구독취소 모달 -->
  <div class="modal mons-modal" id="cancel-subs" tabindex="-1" aria-labelledby="CancelSubscribe" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header"><i class="icon-ban"></i> 구독해지</div>
        <div class="modal-body">
          <div class="text-center pt-4">
            <span>정기구독을 정말로 해지 하시겠습니까?</span>
          </div>
          <div class="subs-cancel-cont">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal" ref="subsClose">취소</button>
          <button class="btn btn-primary" @click="cancelSubscription">구독해지</button>
        </div>
      </div>
    </div>
  </div>

  <!-- 구독주기 변경 모달 -->
  <div class="modal mons-modal" id="period-edit" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header"><i class="icon-reload"></i> 구독주기 변경</div>
        <div class="modal-body">
          <div class="text-center pt-4">
            <span>구독 주기를 변경 하시겠습니까?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal"  @click="selectedPeriod = subscription.period"
                  ref="periodClose">취소</button>
          <button class="btn btn-primary" @click="modifyPeriod">변경하기</button>
        </div>
      </div>
    </div>
  </div>

  <!-- 상품삭제 확인 모달 -->
  <div class="modal mons-modal" id="item-del" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">상품삭제</div>
        <div class="modal-body">
          <div class="text-center pt-4">
            <span>선택하신 상품을 삭제 하시겠습니까?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal" ref="itemDelClose">취소</button>
          <button class="btn btn-primary" @click="deleteSubsItem">삭제하기</button>
        </div>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'
import LGnb from '@/components/mypage/LGnb'
import DeliveryModal from '@/components/order/DeliveryModal'
import vDatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'

export default {
  name: 'SubscriptionDetail',
  components: {
    Navi, Footer, LGnb, DeliveryModal, vDatePicker
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      parentType: 'mypage',
      url: 'subscriptions',
      repeatOrderId: this.$route.query.repeatOrderId || '',
      subscription: null,
      subscriptionItem: null,
      subscriptionOrderItems: null,
      editSubscriptionOrderItems: null,
      totalAmount: 0,
      totalSaleAmount: 0,
      delivery4Repeat: null,
      deliveryModalType: 'add',
      isEdit: false,
      deleteSubsItemIndex: null,
      selectedPeriod: null,
      paymentDate: '',
      afterNextPaymentDay: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYYMMDD'
      }
    }
  },
  watch: {
    subscription: {
      handler() {
        this.afterNextPaymentDay = moment(this.subscription.nextPaymentDay).add(this.subscription.period * 7, 'd')
        this.paymentDate = moment(this.subscription.nextPaymentDay).toDate()
      }
    }
  },
  created() {
    this.getSubscription()
  },
  methods: {
    getSubscription() {
      axios.get('/api/mypage/subscription/' + this.repeatOrderId).then(({ data }) => {
        console.log(data.result)
        this.subscription = data.result
        this.delivery4Repeat = data.result.delivery4Repeat
        this.subscriptionOrderItems = this.convertToObject(data.result.subscriptionOrderItemsJson)
        this.selectedPeriod = data.result.period
        // this.getOrder(data.result.lastOrderId)
      }).catch((error) => {
        console.log(error)
      })
    },
    getOrder(orderId) {
      axios.get('/api/mypage/order/' + orderId).then(({ data }) => {
        console.log(data.result)
        this.order = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    convertToObject(json) {
      return JSON.parse(json)
    },
    modifySubsItems() {
      axios.post('/api/mypage/subscription/modifySubsOrderItems',
        {
          repeatOrderId: this.subscription.repeatOrderId,
          subsOrderItems: JSON.stringify(this.editSubscriptionOrderItems)
        }).then(({ data }) => {
        console.log(data.result)
        this.subscription = data.result
        this.subscriptionOrderItems = this.convertToObject(data.result.subscriptionOrderItemsJson)
        this.isEdit = false
      }).catch((error) => {
        console.log(error)
      })
    },
    modifyNextPayment() {
      if (this.paymentDate === '' || this.paymentDate === null) {
        alert('변경할 결제일을 선택해주세요.')
        return
      }
      axios.post('/api/mypage/subscription/modifyNextPayment',
        { repeatOrderId: this.subscription.repeatOrderId, nextPayment: this.paymentDate }).then(({ data }) => {
        console.log(data.result)
        this.subscription = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    modifyDelivery(addressDTO) {
      this.delivery4Repeat = addressDTO
      this.delivery4Repeat.repeatOrderId = this.repeatOrderId
      axios.post('/api/mypage/subscription/modifyDelivery', this.delivery4Repeat).then(({ data }) => {
        console.log(data.result)
        this.delivery4Repeat = data.result.delivery4Repeat
      }).catch((error) => {
        console.log(error)
      })
      console.log(this.repeatOrderDTO)
    },
    cancelSubscription() {
      axios.post('/api/mypage/subscription/modifyCancel', { repeatOrderId: this.subscription.repeatOrderId }).then(({ data }) => {
        console.log(data.result)
        this.subscription = data.result
        this.$refs.subsClose.click()
        this.$router.push({ name: 'Subscriptions' })
      }).catch((error) => {
        console.log(error)
      })
    },
    modifyPeriod() {
      axios.post('/api/mypage/subscription/modifyPeriod',
        { repeatOrderId: this.subscription.repeatOrderId, period: this.selectedPeriod }).then(({ data }) => {
        console.log(data.result)
        this.subscription = data.result
        this.$refs.periodClose.click()
      }).catch((error) => {
        console.log(error)
      })
    },
    itemEdit(is) {
      this.isEdit = is
      this.editSubscriptionOrderItems = JSON.parse(JSON.stringify(this.subscriptionOrderItems))
      if (!this.subscriptionItem) {
        axios.get('/api/subscriptionItem/' + this.subscription.subscriptionItemId).then(({ data }) => {
          console.log(data.result)
          this.subscriptionItem = data.result
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    deleteSubsItem(index) {
      if (this.editSubscriptionOrderItems.length < 2) {
        alert('삭제할 수 없습니다.')
        this.$refs.itemDelClose.click()
        return
      }
      this.editSubscriptionOrderItems.splice(this.deleteSubsItemIndex, 1)
      this.$refs.itemDelClose.click()
    },
    smallImage(item) {
      for (let i = 0; i < item.itemImages.length; i++) {
        if (item.itemImages[i].itemImageType === 'S') {
          return this.gcpUrl + item.itemImages[i].cloudPath
        }
      }
    },
    up(orderItem) {
      orderItem.itemCount = orderItem.itemCount + 1
    },
    down(orderItem) {
      if (orderItem.itemCount < 2) {
        return
      }
      orderItem.itemCount = orderItem.itemCount - 1
    },
    totalAmountCal() {
      let subsTotalAmount = 0
      for (let i = 0; i < this.subscriptionOrderItems.length; i++) {
        subsTotalAmount += this.subscriptionOrderItems[i].itemCount * this.subscriptionOrderItems[i].itemPrice
      }
      return subsTotalAmount
    },
    totalSaleAmountCal() {
      let subsTotalSaleAmount = 0
      for (let i = 0; i < this.subscriptionOrderItems.length; i++) {
        subsTotalSaleAmount += this.subscriptionOrderItems[i].itemCount * this.subscriptionOrderItems[i].itemSalePrice
      }
      return subsTotalSaleAmount
    }
  }
}
</script>

<style scoped>

</style>

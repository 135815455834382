import axios from 'axios'

export default {
  namespaced: true,
  state: {
    status: '',
    token: localStorage.getItem('token') || null,
    user: [],
    oauth2User: {
      properties: {
        nickname: null
      },
      kakao_account: {
        email: null,
        age_range: null,
        birthday: null,
        gender: null
      }
    }
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, data) {
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user = null
    },
    oauth2User(state, data) {
      state.oauth2User = data
    }
  },
  actions: {
    login({ commit }, detail) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        detail.tempUserId = localStorage.getItem('tempuid')
        axios.post('/api/login', detail).then(({ data }) => {
          localStorage.setItem('token', data.token)
          localStorage.setItem('refresh', data.refreshToken)
          localStorage.setItem('GAUniqueId', data.user.userId)
          axios.defaults.headers.common.Authorization = data.token
          localStorage.removeItem('tempuid')
          commit('auth_success', data)
          resolve(data)
        }).catch((error) => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          localStorage.removeItem('GAUniqueId')
          reject(error.response.data.message)
        })
      })
    },
    oauth2Login({ commit }, accessToken) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        const tempuid = localStorage.getItem('tempuid')
        axios.post('/api/oauth2Login', { accessToken: accessToken, provider: 'kakao', tempUserId: tempuid }).then(({ data }) => {
          if (data.isUser === 'yes') {
            localStorage.setItem('token', data.token)
            localStorage.setItem('refresh', data.refreshToken)
            localStorage.setItem('GAUniqueId', data.user.userId)
            axios.defaults.headers.common.Authorization = data.token
            localStorage.removeItem('tempuid')
            commit('auth_success', data)
          } else {
            commit('oauth2User', data.kakaoProfile)
          }
          resolve(data)
        }).catch((error) => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          localStorage.removeItem('GAUniqueId')
          reject(error.response.data.message)
        })
      })
    },
    // refresh token을 이용한 access token 가져오는 부분은 main.js axios 인터셉터에서 수행. 아래 function 이용 안함.
    refresh({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        const refreshToken = localStorage.getItem('refresh')
        axios.post('/api/refresh', { refreshToken: refreshToken }).then(({ data }) => {
          console.log('refresh: ' + data.token)
          localStorage.setItem('token', data.token)
          localStorage.setItem('GAUniqueId', data.user.userId)
          axios.defaults.headers.common.Authorization = data.token
          commit('auth_success', data)
          resolve(data)
        }).catch((error) => {
          commit('auth_error')
          /* localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          localStorage.removeItem('GAUniqueId') */
          reject(error)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        localStorage.removeItem('GAUniqueId')
        delete axios.defaults.headers.common.Authorization
        resolve()
      })
    },
    moveCart() {
      const tempuid = localStorage.getItem('tempuid')
      alert(tempuid)
      if (tempuid !== null) {
        axios.get('/api/cart/moveCart', { params: { tempUserId: tempuid } }).then(() => {
          localStorage.removeItem('tempuid')
        }).catch((error) => {
          console.log(error)
        })
      }
    }
  },
  getters: {
    // isLogin: state => !!state.token,
    isLogin(state) {
      return !!state.token
    },
    user: state => state.user,
    oauth2User: function (state) {
      return state.oauth2User
    }
  }
}

<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="cont-wrapper max-limit">
      <LGnb :url="url"></LGnb>
      <div class="main-box">
        <div class="atit-wrap">
          <h2><i class="icon-bag"></i> 주문조회</h2>
          <span>구독하신 상품의 배송 정보 등을 확인 하실 수 있습니다.</span>
        </div>
        <div class="order-box" v-for="(order, $index) in orderList" :key="$index">
          <div class="order-header">
            <span class="date">주문일자 {{ new Date(order.createDate) | moment("YYYY.MM.DD") }}</span>
            <button class="btn btn-sm" @click="orderDetail(order)">주문상세보기 &#62;</button>
          </div>
          <div class="item-box">
            <ul class="item-header">
              <li>
                <span class="tt">주문번호 </span>
                <span class="ts">{{order.orderId}}</span>
              </li>
              <li>
                <span class="status">진행상태 :</span>
                <span class="accent-color">{{ order.orderStatusName }}</span>
                <button @click="viewDeliveryInfo(order.delivery.logisticsCompany, order.delivery.trackingNumber)"
                        v-if="order.orderStatus === 'Shipping'" class="btn btn-sm btn-primary">배송조회</button>
              </li>
            </ul>
            <ul class="item-wrap">
              <li class="item-goods" v-for="orderItem in order.orderItems" :key="orderItem.orderItemId">
                <div class="item-thumb">
                  <img :src="smallImage(orderItem.item)" alt="썸네일"/>
                </div>
                <div class="item-cont-wrap">
                  <div class="item-info">
                    <span class="name">{{orderItem.orderItemName}}</span>
                    <span class="option"></span>
                  </div>
                  <div class="goods-price">
                    <div class="price-wrap">
                      <span class="qty">{{orderItem.itemCount}}개</span>
                      <span class="price">{{ order.totalSaleAmount | makeComma }}원</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <InfiniteLoading @infinite="infiniteHandler" spinner="spiral">
          <div slot="no-more"></div>
          <div slot="no-results">
            <div class="empty-cont">
<!--              <img src="images/icon-cart.png" alt="cart icon">-->
              <span class="text">주문하신 내역이 없습니다.</span>
            </div>
          </div>
        </InfiniteLoading>
      </div>
    </div>
  </div>
  <OrderProcessInfoModal></OrderProcessInfoModal>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'
import LGnb from '@/components/mypage/LGnb'
import OrderProcessInfoModal from '@/components/mypage/OrderProcessInfoModal'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Orders',
  components: {
    Navi, Footer, LGnb, OrderProcessInfoModal, InfiniteLoading
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      parentType: 'init',
      url: 'orders',
      page: 0,
      size: 5,
      orderList: [],
      isComplete: false
    }
  },
  created() {
    // this.getOrderList()
  },
  methods: {
    getOrderInit() {
      axios.get('/api/mypage/orders',
        { params: { page: 0, size: this.size, sort: '' } }).then(({ data }) => {
        console.log(data.result.content)
        this.page = 1
        this.orderList = data.result.content
        this.isComplete = true
      }).catch((error) => {
        console.log(error)
        this.isComplete = true
      })
    },
    orderDetail(order) {
      this.$router.push({ path: 'orderDetail', query: { orderId: order.orderId } })
    },
    goCancel(orderId) {
      axios.get('/api/order/cancel/' + orderId).then(({ data }) => {
        console.log(data)
        alert('결제가 취소되었습니다.')
        for (let i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].orderId === orderId) {
            this.orderList[i].orderStatus = 'Cancel'
            this.orderList[i].orderStatusName = '주문취소'
          }
        }
        // this.getOrderInit()
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    viewDeliveryInfo(logisticsCompany, trackingNumber) {
      // todo. 임시로 구분만 해놓음..
      if (logisticsCompany === 'KGB') {
        window.open('https://www.ilogen.com/m/personal/trace.pop/' + trackingNumber)
      } else if (logisticsCompany === 'CJGLS') {
        window.open('https://www.cjlogistics.com/ko/tool/parcel/tracking/' + trackingNumber) // todo. url 확인 필요.
      } else if (logisticsCompany === 'HYUNDAI') {
        window.open('롯데택배 url' + trackingNumber) // todo. url 확인 필요.
      }
    },
    infiniteHandler($state) {
      axios.get('/api/mypage/orders',
        { params: { page: this.page, size: this.size, sort: '' } }).then(({ data }) => {
        console.log(data.result)
        this.isComplete = true
        if (data.result.content.length) {
          this.page += 1
          for (let i = 0; i < data.result.content.length; i++) {
            this.orderList.push(data.result.content[i])
          }
          $state.loaded()
        } else {
          $state.complete()
        }
      }).catch((error) => {
        console.log(error)
        $state.complete()
      })
    },
    colorClass(orderStatus) {
      if (orderStatus === 'Pay' || orderStatus === 'Shipping' || orderStatus === 'PreDelivery') {
        return 'process-color'
      } else if (orderStatus === 'Done') {
        return 'success-color'
      }
      return 'danger-color'
    },
    smallImage(item) {
      for (let i = 0; i < item.itemImages.length; i++) {
        if (item.itemImages[i].itemImageType === 'S') {
          return this.gcpUrl + item.itemImages[i].cloudPath
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <body class="d-flex flex-column h-100">
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont gray-wrapper" v-if="itemInfo">
    <div class="main-header">
      <div class="container-fluid max-limit">
        <div class="atit-wrap">
          <h2><i class="icon-bag"></i>주문확인</h2>
          <span>주문하신 상품과 정기 결제에 대해 확인 해주세요</span>
        </div>
      </div>
    </div>
    <div class="cont-wrapper max-limit">
      <div class="main-box">
        <div class="order-check">
          <div class="sub-title-wrap">
            <h3 class="title">오늘 결제</h3>
          </div>
          <ul class="item-wrap">
            <li class="item-goods">
              <div class="item-thumb">
                <div v-for="image in itemInfo.itemImages" v-bind:key="image.itemImageId">
                  <img v-if="image.itemImageType === 'S'" v-bind:src="gcpUrl+image.cloudPath" alt="small image"/>
                </div>
              </div>
              <div class="item-cont-wrap">
                <div class="item-info">
                  <span class="name">{{itemInfo.name}}</span>
                  <span class="option"></span>
<!--                  <button class="btn btn-sm btn-outline-primary mt-2" data-bs-toggle="modal" data-bs-target="#edit-order">상품수량 변경</button>-->
                </div>
                <div class="goods-price">
                  <div class="qty-wrap">
                    <div class="btn-group" role="group">
                      <button class="btn btn-sm btn-outline-primary" v-on:click="down()">-</button>
                      <input type="text" disabled :value="repeatOrderDTO.orderDTO.items[0].itemCount">
                      <button class="btn btn-sm btn-outline-primary" v-on:click="up()">+</button>
                    </div>
                  </div>
                  <div class="price-wrap">
                    <span class="qty">{{repeatOrderDTO.orderDTO.items[0].itemCount}}개</span>
                    <span class="price">{{ subsAmount() | makeComma }}원</span>
                  </div>
<!--                  <div class="item-del">
                    <a href="#" class="btn-del">X 삭제</a>
                  </div>-->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="next-order">
          <div class="sub-title-wrap">
            <h3 class="title">4주 뒤 결제 <span>&#40;{{ new Date() | moment("add","4 weeks","YYYY년MM월DD일") }}&#41;</span></h3>
            <span><i class="icon-refresh"></i> 4주마다 결제</span>
          </div>
          <ul class="item-wrap">
            <li class="item-goods">
              <div class="item-thumb">
                <div v-for="image in itemInfo.itemImages" v-bind:key="image.itemImageId">
                  <img v-if="image.itemImageType === 'S'" v-bind:src="gcpUrl+image.cloudPath" alt="small image"/>
                </div>
              </div>
              <div class="item-cont-wrap">
                <div class="item-info">
                  <span class="name">{{itemInfo.name}}</span>
                </div>
                <div class="goods-price">
                  <div class="price-wrap">
                    <span class="qty">{{repeatOrderDTO.orderDTO.items[0].itemCount}}개</span>
                    <span class="price">{{ subsAmount() | makeComma }}원</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="sub-box">
        <div class="sub-title-wrap">
          <h3 class="title">결제정보</h3>
        </div>
        <ul class="payment-info">
          <li>
            <span>상품금액</span>
            <span>{{ subsAmount() | makeComma }}원</span>
          </li>
          <li>
            <span>배송비</span>
            <span>0원</span>
          </li>
          <li>
            <span>할인금액</span>
            <span>{{ saleAmount() | makeComma }}원</span>
          </li>
          <li>
            <span>결제 예정 금액</span>
            <span>{{ subsAmount() - saleAmount() | makeComma }}원</span>
          </li>
        </ul>
        <span class="form-info text-center p-4">주문 내용을 확인 하였으며, 구독서비스 가입 및 구독 결제에 동의 합니다.</span>
        <div class="d-grid">
          <button class="btn btn-xl btn-primary" @click="initSubscriptionOrder">주문 확인</button>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Footer from '@/components/Footer'

export default {
  name: 'SubscriptionOrderConfirm',
  components: {
    Footer
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      selectedOption: null,
      subscriptionItem: null,
      subscriptionOrderItems: [],
      totalAmount: 0,
      totalSaleAmount: 0,
      parentType: 'Order',
      itemInfo: null,
      repeatOrderDTO: {
        repeatOrderId: 0,
        repeatOrderStatus: 'Ready',
        subscriptionItemId: '',
        subscriptionName: '',
        lastOrderId: 0,
        period: 4,
        nextPaymentDay: '',
        userId: 0,
        subscriptionOrderItemsJson: null,
        orderDTO: {
          orderId: 0,
          orderStatus: 'Ready',
          orderType: 'FIRST_SUBSCRIPTION',
          repeatOrderId: 0,
          userId: 0,
          totalAmount: 0,
          totalSaleAmount: 0,
          items: [],
          paymentDTOs: [],
          deliveryDTO: null
        }
      }
    }
  },
  created() {
    this.repeatOrderDTO.orderDTO = this.orderDTO()
    this.getItemInfo()
  },
  methods: {
    getItemInfo() {
      axios.get('/api/item/' + this.repeatOrderDTO.orderDTO.items[0].itemId).then(({ data }) => {
        console.log(data.result)
        this.itemInfo = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    ...mapGetters({
      isLogin: 'userStore/isLogin',
      subsSelectInfo: 'orderStore/subsSelectInfo',
      subsOrderItems: 'orderStore/subsOrderItems',
      orderDTO: 'orderStore/orderDTO'
    }),
    ...mapActions({
      setSubsOrderItems: 'orderStore/setSubsOrderItems',
      setRepeatOrderDTO: 'orderStore/setRepeatOrderDTO'
    }),
    subsAmount() {
      return this.itemInfo.price * this.repeatOrderDTO.orderDTO.items[0].itemCount
    },
    saleAmount() {
      return this.itemInfo.price * this.repeatOrderDTO.orderDTO.items[0].itemCount -
        this.itemInfo.salePrice * this.repeatOrderDTO.orderDTO.items[0].itemCount
    },
    initSubscriptionOrder() {
      // this.setSubsOrderItems(this.subscriptionOrderItems)
      if (!this.isLogin()) {
        this.$router.push('/login?redirect=/subscriptionOrderConfirm')
        return
      }

      this.repeatOrderDTO.subscriptionItemId = this.itemInfo.itemId
      this.repeatOrderDTO.subscriptionOrderItemsJson = JSON.stringify(this.repeatOrderDTO.orderDTO.items)
      axios.post('/api/repeatOrder/initOrder', this.repeatOrderDTO).then(({ data }) => {
        console.log(data)
        this.repeatOrderDTO.repeatOrderId = data.result.repeatOrderId
        this.repeatOrderDTO.lastOrderId = data.result.lastOrderId
        this.repeatOrderDTO.orderDTO.orderId = data.result.lastOrderId
        this.repeatOrderDTO.orderDTO.totalAmount = data.order.totalAmount
        this.repeatOrderDTO.orderDTO.totalSaleAmount = data.order.totalSaleAmount
        // this.setSubsOrderItems(this.subscriptionOrderItems)
        this.setRepeatOrderDTO(this.repeatOrderDTO)
        this.$router.push({ name: 'SubscriptionPayment' })
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    up() {
      this.repeatOrderDTO.orderDTO.items[0].itemCount = this.repeatOrderDTO.orderDTO.items[0].itemCount + 1
    },
    down() {
      if (this.repeatOrderDTO.orderDTO.items[0].itemCount < 2) {
        return
      }
      this.repeatOrderDTO.orderDTO.items[0].itemCount = this.repeatOrderDTO.orderDTO.items[0].itemCount - 1
    },
    back() {
      this.$router.push('/itemDetail?itemId=' + this.itemInfo.itemId)
    }
  }
}
</script>

<style scoped>

</style>

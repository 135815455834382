<template>
  <body class="d-flex flex-column h-100">

  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>

  <div class="container-fluid main-cont flex-grow-1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2">
          <h2>새로운 비밀번호를<br>입력해 주세요</h2>
          <span>비밀번호는 6자 이상으로 입력 하세요</span>
        </div>
        <div class="p2 flex-grow-1">
          <form @submit.prevent="submitForm">
            <div class="abox-cont">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="mb-4">
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-envelope"></i></span>
                  <input v-if="!isPasswordView" class="form-control binput" type="password" name="password"
                         v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="새로운 비밀번호를 입력하세요">
                  <input v-else class="form-control binput" type="text" name="password" v-model="form.password"
                         :class="{ 'is-invalid': submitted && $v.form.password.$error}" placeholder="새로운 비밀번호를 입력하세요">
                  <div v-if="submitted && !$v.form.password.required" id="validationPASS" class="invalid-feedback">
                    비밀번호를 확인해주세요
                  </div>
                  <div class="invalid-feedback" v-if="!$v.form.password.minLength">
                    비밀번호는 {{$v.form.password.$params.minLength.min}}자 이상으로 입력해주세요.
                  </div>
                </div>
                <div class="form-check pt-2">
                  <input type="checkbox" class="form-check-input" v-model="isPasswordView">
                  <label class="form-check-label">비밀번호 보기</label>
                </div>
                <div class="mb-3 d-grid gap-3">
                  <button class="btn btn-xl btn-primary">비밀번호 재설정 완료</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import Footer from '@/components/Footer'
import { minLength, required } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'ChangePW',
  components: { Footer },
  data() {
    return {
      form: {
        password: null,
        resetKey: this.$route.query.key || null
      },
      submitted: false,
      errorMessage: null,
      isPasswordView: false
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    submitForm() {
      this.submitted = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const sForm = new FormData()
      sForm.append('password', this.form.password)
      sForm.append('resetKey', this.form.resetKey)

      axios.post('/api/resetPwProcess', sForm).then(({ data }) => {
        console.log(data.result)
        alert('비밀번호가 변경되었습니다.')
        this.$router.push('/')
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    back() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>

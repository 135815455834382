<template>
  <body class="d-flex flex-column h-100">

  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>

  <div class="container-fluid main-cont gray-wrapper">
    <div class="main-header">
      <div class="container-fluid max-limit">
        <div class="atit-wrap">
          <h2><i class="icon-credit-card"></i> 결제하기</h2>
          <span>배송지 정보와 결제 수단을 선택 해주세요</span>
        </div>
      </div>
    </div>
    <div class="cont-wrapper max-limit">
      <div class="main-box">
        <div class="delivery-wrap">
          <div class="sub-title-wrap">
            <h3 class="title">배송지 정보</h3>
            <a href="#manage-adress" data-bs-toggle="modal" data-bs-target="#manage-adress">배송지 목록 &#62;</a>
          </div>
          <ul v-if="recentDelivery" class="delivery-info">
            <li class="name">{{ recentDelivery.receiver }}</li>
            <li class="adress">&#91;{{ recentDelivery.zipCode }}&#93; {{ recentDelivery.address1 }}
              {{ recentDelivery.address2 }}
            </li>
            <li class="tel">{{ recentDelivery.mobilePhone }}</li>
            <button class="btn btn-outline-primary block-btn" data-bs-toggle="modal" data-bs-target="#add-adress"><i
              class="icon-location-pin"></i> 새로운 배송지</button>
          </ul>
          <div v-else class="gray-box">
            <span class="big-icon"><i class="icon-map"></i></span>
            <span class="form-info text-center">배송지 정보가 없습니다.<br>배송지 정보를 등록해 주세요.</span>
            <button class="btn btn-outline-primary block-btn" data-bs-toggle="modal" data-bs-target="#add-adress"><i
              class="icon-location-pin"></i> 새로운 배송지</button>
          </div>
        </div>
        <div class="order-check">
          <div class="sub-title-wrap">
            <h3 class="title">구독하신 상품</h3>
          </div>
          <ul class="item-wrap" v-if="itemInfo">
            <li class="item-goods">
              <div class="item-thumb">
                <div v-for="image in itemInfo.itemImages" v-bind:key="image.itemImageId">
                  <img v-if="image.itemImageType === 'S'" v-bind:src="gcpUrl+image.cloudPath" alt="small image"/>
                </div>
              </div>
              <div class="item-cont-wrap">
                <div class="item-info">
                  <span class="name">{{itemInfo.name}}</span>
                </div>
                <div class="goods-price">
                  <div class="price-wrap">
                    <span class="qty">{{repeatOrderDTO.orderDTO.items[0].itemCount}}개</span>
                    <span class="price">{{ subsAmount() | makeComma }}원</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="sub-box">
        <div class="sub-title-wrap">
          <h3 class="title">결제정보</h3>
        </div>
        <ul class="payment-info">
          <li>
            <span>상품금액</span>
            <span>{{ repeatOrderDTO.orderDTO.totalAmount | makeComma }}원</span>
          </li>
          <li>
            <span>배송비</span>
            <span>0원</span>
          </li>
          <li>
            <span>할인금액</span>
            <span><i
              class="sale-badge">10%구독할인</i> -{{
                repeatOrderDTO.orderDTO.totalAmount - repeatOrderDTO.orderDTO.totalSaleAmount | makeComma
              }}원</span>
          </li>
          <li>
            <span>결제 예정 금액</span>
            <span>{{ repeatOrderDTO.orderDTO.totalSaleAmount | makeComma }}원</span>
          </li>
        </ul>
        <div class="payment-sel">
          <div class="sub-title-wrap">
            <h3 class="title">결제수단 선택</h3>
          </div>
          <div class="d-grid pay-button">
            <input type="radio" class="btn-check" name="options" id="option1" v-model="paymentKind"
                   value="KAKAOPAY_REPEAT" autocomplete="off">
            <label class="btn btn-outline-primary" for="option1"><img src="images/kakaopay.png"
                                                                      alt="kakao pay logo">카카오
              페이</label>

            <input type="radio" class="btn-check" name="options" id="option2" v-model="paymentKind"
                   value="NAVERPAY_REPEAT" autocomplete="off">
            <label class="btn btn-outline-primary" for="option2"><img src="images/naverpay.png"
                                                                      alt="naver pay logo">네이버
              페이</label>

            <!--            <input type="radio" class="btn-check" name="options" id="option3" v-model="paymentKind"
                               value="CREDIT_REPEAT" autocomplete="off">
                        <label class="btn btn-outline-primary" for="option3"><img src="images/creditcard.png"
                                                                                  alt="credit card icon">신용카드</label>-->
          </div>
        </div>
        <span class="form-info text-center p-4">주문 내용을 확인 하였으며,<br>구독서비스 가입 및 구독 결제에 동의 합니다.</span>
        <div class="d-grid">
          <button href="#" class="btn btn-xl btn-primary" @click="initPayment">결제하기</button>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  <DeliveryListModal></DeliveryListModal>
  <DeliveryModal v-bind:type="deliveryModalType"></DeliveryModal>
  </body>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Footer from '@/components/Footer'
import DeliveryListModal from '@/components/order/DeliveryListModal'
import DeliveryModal from '@/components/order/DeliveryModal'

export default {
  name: 'SubscriptionPayment',
  components: {
    Footer, DeliveryListModal, DeliveryModal
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      recentDeliveryList: null,
      recentDelivery: null,
      subscriptionItem: null,
      selectedOption: null,
      subscriptionOrderItems: [],
      itemInfo: null,
      repeatOrderDTO: null,
      paymentKind: null,
      deliveryModalType: 'add'
    }
  },
  created() {
    this.getDefaultDelivery()
    // this.selectedOption = this.subsSelectInfo()
    this.repeatOrderDTO = this.repeatOrder()
    // this.getSubscription()
    this.getItemInfo()
  },
  methods: {
    ...mapGetters({
      subsSelectInfo: 'orderStore/subsSelectInfo',
      subsOrderItems: 'orderStore/subsOrderItems',
      repeatOrder: 'orderStore/repeatOrder'
    }),
    ...mapActions({
      setRepeatOrderDTO: 'orderStore/setRepeatOrderDTO'
    }),
    getItemInfo() {
      axios.get('/api/item/' + this.repeatOrderDTO.orderDTO.items[0].itemId).then(({ data }) => {
        console.log(data.result)
        this.itemInfo = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    getDefaultDelivery() {
      axios.get('/api/order/recentDelivery').then(({ data }) => {
        console.log(data.result)
        this.recentDeliveryList = data.result
        if (data.result.length > 0) {
          this.recentDelivery = this.recentDeliveryList[0]
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    subsAmount() {
      return this.itemInfo.price * this.repeatOrderDTO.orderDTO.items[0].itemCount
    },
    initPayment() {
      const paymentArray = []
      paymentArray.push({ paymentType: this.paymentKind })
      this.repeatOrderDTO.orderDTO.paymentDTOs = paymentArray
      this.repeatOrderDTO.orderDTO.deliveryDTO = this.recentDelivery

      console.log(this.repeatOrderDTO)
      if (this.recentDelivery == null) {
        alert('배송지 정보를 입력해주세요.')
        return
      }
      if (this.paymentKind === null) {
        alert('결제수단을 선택해주세요.')
        return
      }
      if (this.paymentKind === 'KAKAOPAY_REPEAT') {
        this.kakaoPayReady()
      } else if (this.paymentKind === 'NAVERPAY_REPEAT') {
        this.naverPayReady()
      } else if (this.paymentKind === 'CREDIT_REPEAT') {
        alert('아직 지원되지 않습니다.')
      }
    },
    kakaoPayReady() {
      console.log(this.repeatOrderDTO)
      axios.post('/api/repeatOrder/kakaoPayReady', this.repeatOrderDTO).then(({ data }) => {
        console.log(data)
        this.repeatOrderDTO.orderDTO.paymentDTOs[0].tid = data.result.tid
        this.setRepeatOrderDTO(this.repeatOrderDTO)
        location.href = data.result.next_redirect_pc_url
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    naverPayReady() {
      this.setRepeatOrderDTO(this.repeatOrderDTO)
      // eslint-disable-next-line no-undef
      var oPay = Naver.Pay.create({
        mode: 'development', // development or production
        payType: 'recurrent', // normal or recurrent
        clientId: 'u86j4ripEt8LRfPGzQ8' // clientId
      })
      oPay.open({
        actionType: 'NEW', // 신규등록 NEW /  결제수단 변경 CHANGE
        targetRecurrentId: '',
        productCode: this.repeatOrderDTO.subscriptionItemId,
        productName: this.repeatOrderDTO.subscriptionName,
        totalPayAmount: this.repeatOrderDTO.orderDTO.totalSaleAmount,
        returnUrl: process.env.VUE_APP_LOCAL + '/subscriptionOrderComplete'
      })
    },
    back() {
      this.$router.push('/subscriptionOrderConfirm')
    },
    modifyDelivery(addressDTO) {
      this.recentDelivery = addressDTO
      this.repeatOrderDTO.orderDTO.deliveryDTO = addressDTO
      console.log(this.repeatOrderDTO)
    }
  }
}
</script>

<style scoped>

</style>

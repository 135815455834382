<template>
  <body class="d-flex flex-column h-100">
  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2 border-b">
          <h2>회원정보</h2>
        </div>
        <div class="p2 flex-grow-1">
          <div class="abox-cont">
            <div class="my-info-wrap" v-if="!isEdit">
              <span class="stitle">기본정보</span>
              <ul class="my-info">
                <li>
                  <span class="tt">이름</span>
                  <span class="ts">{{ user.username }}</span>
                </li>
                <li>
                  <span class="tt">이메일주소</span>
                  <span class="ts">{{ user.emailAddress }}</span>
                </li>
                <li>
                  <span class="tt">휴대폰 번호</span>
                  <span class="ts">{{ user.mobilePhone }}</span>
                </li>
                <li>
                  <span class="tt">혜택 알림</span>
                  <div class="ts">
                    <ul class="benefit-sel">
                      <li>
                        <span class="tt">마케팅 수신 동의</span>
                        <span class="ts">
                          <em v-if="user.agreeMarketing === 'Y'">동의함</em>
                          <em v-else>동의 안함</em>
                        </span>
                      </li>
                    </ul>
                    <span class="info-text">이벤트, 할인정보 등을 보내 드립니다.</span>
                  </div>
                </li>
                <li>
                  <span class="tt"></span>
                  <span class="ts">
                    <a class="btn btn-sm btn-outline-primary" @click="itemEdit(true)">기본정보 수정</a>
                  </span>
                </li>
              </ul>
            </div>
            <div class="my-info-wrap" v-if="isEdit">
              <span class="stitle">기본정보</span>
              <ul class="my-info">
                <li>
                  <span class="tt">이름</span>
                  <span class="ts">
                    <input type="text" class="form-control" v-model="userForm.username">
                  </span>
                </li>
                <li>
                  <span class="tt">이메일주소</span>
                  <span class="ts">{{ user.emailAddress }}</span>
                </li>
                <li>
                  <span class="tt">휴대폰 번호</span>
                  <span class="ts">
                    <input type="text" class="form-control" placeholder="휴대폰번호(- 없이 입력해주세요)"
                           @keyup="autoCompleteHypen(userForm.mobilePhone)" v-model="userForm.mobilePhone">
                  </span>
                </li>
                <li>
                  <span class="tt">혜택 알림</span>
                  <div class="ts">
                    <ul class="benefit-sel">
                      <li>
                        <span class="tt">마케팅 수신 동의</span>
                        <span class="ts">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="benefit-select" type="radio" id="benefitOn"
                                   value="Y" v-model="userForm.agreeMarketing">
                            <label class="form-check-label" for="benefitOn">동의함</label>
                          </div>
                          <div class="form-check form-check-inline">
                          <input class="form-check-input" name="benefit-select" type="radio" id="benefitOff"
                                 value="N" v-model="userForm.agreeMarketing">
                          <label class="form-check-label" for="benefitOff">동의 안함</label>
                          </div>
                        </span>
                      </li>
                    </ul>
                    <span class="info-text">이벤트, 할인정보 등을 보내 드립니다.</span>
                  </div>
                </li>
                <li>
                  <span class="tt"></span>
                  <span class="ts">
                    <button class="btn btn-sm btn-outline-primary" @click="itemEdit(false)">취소</button>
                    <button class="btn btn-sm btn-primary" @click="modifyUser">수정완료</button>
                  </span>
                </li>
              </ul>
            </div>
            <div class="my-info-wrap">
              <span class="stitle">회원탈퇴</span>
              <p>회원 탈퇴를 원하시면 탈퇴신청 버튼을 눌러 주세요</p>
              <a href="/userWithdraw" class="btn btn-sm btn-outline-primary">탈퇴신청</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer'

export default {
  name: 'UserModify',
  components: {
    Footer
  },
  data() {
    return {
      isEdit: false,
      user: {
        birthday: '',
        emailAddress: '',
        mobilePhone: '',
        userId: '',
        username: '',
        agreeMarketing: ''
      },
      userForm: {
        mobilePhone: '',
        username: '',
        agreeMarketing: ''
      }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      axios.get('/api/user/me/').then(({ data }) => {
        this.user = data.result
        this.userForm = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    modifyUser() {
      axios.post('/api/mypage/modifyUser', this.userForm).then(({ data }) => {
        alert('회원정보 수정되었습니다.')
        this.isEdit = false
      }).catch((error) => {
        console.log(error)
      })
    },
    itemEdit(is) {
      this.isEdit = is
    },
    back() {
      this.$router.go(-1)
    },
    autoCompleteHypen(val) {
      const res = this.getPhoneMask(val)
      this.userForm.mobilePhone = res
    },
    getPhoneMask(str) {
      str = str.replace(/[^0-9]/g, '')
      let tmp = ''
      if (str.length < 4) {
        return str
      } else if (str.length < 7) {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3)
        return tmp
      } else if (str.length < 11) {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3, 3)
        tmp += '-'
        tmp += str.substr(6)
        return tmp
      } else {
        tmp += str.substr(0, 3)
        tmp += '-'
        tmp += str.substr(3, 4)
        tmp += '-'
        tmp += str.substr(7)
        return tmp
      }
    }
  }
}
</script>

<style scoped>

</style>

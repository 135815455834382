<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 gray-wrapper">
    <div class="cont-wrapper max-limit">
      <LGnb :url="url"></LGnb>
      <div class="main-box" v-if="order">
        <div class="atit-wrap">
          <h2><i class="icon-bag"></i> 주문 상세정보</h2>
        </div>
        <div class="order-box">
          <div class="order-header">
            <span class="date">주문일자 {{ new Date(order.createDate) | moment("YYYY.MM.DD") }}</span>
          </div>
          <div class="item-box">
            <ul class="item-header">
              <li>
                <span class="tt">주문번호 </span>
                <span class="ts">{{ order.orderId }}</span>
              </li>
              <li>
                <span class="status">진행상태 :</span>
                <span :class="colorClass(order.orderStatus)">{{ order.orderStatusName }}</span>
                <button class="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                        data-bs-target="#cancel-order" v-if="order.orderStatus === 'Pay'">주문취소</button>
              </li>
            </ul>
            <ul class="item-wrap">
              <li class="item-goods" v-for="orderItem in order.orderItems" :key="orderItem.orderItemId">
                <div class="item-thumb">
                  <img :src="smallImage(orderItem.item)" alt="제품 썸네일">
                </div>
                <div class="item-cont-wrap">
                  <div class="item-info">
                    <span class="name">{{ orderItem.orderItemName }}<span v-if="orderItem.orderItemOptionDesc"> : &nbsp; </span>{{ orderItem.orderItemOptionDesc}}</span></span>
                    <span class="option"></span>
                  </div>
                  <div class="goods-price">
                    <div class="price-wrap">
                      <span class="qty">{{ orderItem.itemCount }}개</span>
                      <span class="price">{{ orderItem.orderSalePrice | makeComma }}원</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <section class="payment">
          <div class="sub-title-wrap">
            <h3 class="title">결제정보</h3>
          </div>
          <ul class="payment-info">
            <li>
              <span>상품금액</span>
              <span>{{ order.totalAmount - order.deliveryAmount | makeComma }}원</span>
            </li>
            <li>
              <span>배 송 비</span>
              <span>{{ order.deliveryAmount | makeComma }}원</span>
            </li>
            <li>
              <span>할인금액</span>
              <span><i class="sale-badge" v-if="order.orderType !=='SINGLE' ">10%구독할인</i> -{{
                  order.totalAmount - order.totalSaleAmount | makeComma
                }}원</span>
            </li>
            <!--            <li>
                          <span>용기할인</span>
                          <span v-if="bottleDiscountAmount > 0">-{{ bottleDiscountAmount | makeComma }}원</span> <span
                          v-else>0원</span>
                        </li>-->
            <li>
              <span>최종 결제금액</span>
              <span>{{ order.totalSaleAmount | makeComma }}원</span>
            </li>
          </ul>
        </section>
        <section>
          <div class="sub-title-wrap">
            <h3 class="title">배송정보</h3>
          </div>
          <ul class="delivery-info">
            <li class="name">{{ order.delivery.receiver }}</li>
            <li class="adress">&#91;{{ order.delivery.zipCode }}&#93; {{ order.delivery.address1 }}
              {{ order.delivery.address2 }}
            </li>
            <li class="tel">{{ order.delivery.mobilePhone }}</li>
            <li class="pt-1" v-if="order.orderStatus === 'Pay'">
              <button class="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                     data-bs-target="#add-adress">
                <i class="icon-pencil"></i> 배송정보 수정
              </button>
            </li>
          </ul>
          <div class="back-page" id="back">
            <a href="/orders" class="btn btn-primary"><i class="icon-arrow-left"></i> 리스트로 돌아가기</a>
          </div>
        </section>
      </div>
    </div>
  </div>
  <Footer></Footer>

  <!-- 주문 취소 모달 -->
  <div class="modal mons-modal" id="cancel-order" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">주문취소</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-cont">
            <span class="title text-center p-3">주문을 취소 하시겠습니까?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-bs-dismiss="modal" ref="orderCancelClose">취소</button>
          <button class="btn btn-primary" @click="goCancel">확인</button>
        </div>
      </div>
    </div>
  </div>
  <div class="loader" v-show="progressing">
    <div class="lds-ellipsis">
      <img src="images/m.png" width="40px" alt="M">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <ExchangeRefundRequestModal :order-id="orderId"></ExchangeRefundRequestModal>
  <DeliveryModal v-bind:type="deliveryModalType"></DeliveryModal>
  </body>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer'
import LGnb from '@/components/mypage/LGnb'
import Navi from '@/components/Navi'
import ExchangeRefundRequestModal from '@/components/order/ExchangeRefundRequestModal'
import DeliveryModal from '@/components/order/DeliveryModal'

export default {
  name: 'OrderDetail',
  components: {
    Navi, Footer, LGnb, ExchangeRefundRequestModal, DeliveryModal
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      url: 'orders',
      orderId: this.$route.query.orderId || 0,
      order: null,
      itemAmount: 0,
      bottleDiscountAmount: 0,
      purchaseReview: null,
      progressing: false,
      newDelivery: null,
      deliveryModalType: 'add'
    }
  },
  created() {
    this.getOrder()
    this.getPurchaseReview()
  },
  methods: {
    getOrder() {
      axios.get('/api/mypage/order/' + this.orderId).then(({ data }) => {
        console.log(data.result)
        this.order = data.result
        this.calItemAmount()
      }).catch((error) => {
        console.log(error)
      })
    },
    goCancel() {
      this.progressing = true
      this.$refs.orderCancelClose.click()
      axios.get('/api/order/cancel/' + this.orderId).then(({ data }) => {
        console.log(data)
        alert('결제가 취소되었습니다.')
        this.getOrder()
        this.progressing = false
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
        this.progressing = false
      })
    },
    getPurchaseReview() {
      axios.get('/api/purchaseReview/getPurchaseReview/' + this.orderId).then(({ data }) => {
        console.log(data.result)
        this.purchaseReview = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    modifyDelivery(addressDTO) {
      this.newDelivery = addressDTO
      this.newDelivery.orderId = this.orderId
      this.newDelivery.deliveryId = this.order.delivery.deliveryId
      axios.post('/api/mypage/order/modifyDelivery', this.newDelivery).then(({ data }) => {
        console.log(data.result)
        this.order.delivery = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    calItemAmount() {
      let amount = 0
      for (let i = 0; i < this.order.orderItems.length; i++) {
        amount += this.order.orderItems[i].orderPrice
      }
      this.itemAmount = amount
    },
    writePurchaseReview() {
      this.$router.push({ path: 'purchaseReviewForm', query: { orderId: this.orderId } })
    },
    colorClass(orderStatus) {
      if (orderStatus === 'Pay' || orderStatus === 'Shipping' || orderStatus === 'PreDelivery') {
        return 'process-color'
      } else if (orderStatus === 'Done') {
        return 'success-color'
      }
      return 'danger-color'
    },
    smallImage(item) {
      for (let i = 0; i < item.itemImages.length; i++) {
        if (item.itemImages[i].itemImageType === 'S') {
          return this.gcpUrl + item.itemImages[i].cloudPath
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

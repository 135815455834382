<template>
  <body class="d-flex flex-column h-100">

  <!-- 공통 내비게이션 --->
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-lg">
      <button v-on:click="back" class="btn"><i class="icon-arrow-left"></i> Back</button>
    </div>
  </nav>

  <div class="container-fluid main-cont flex-grow-1">
    <div class="article-box-wrap">
      <div class="article-box d-flex flex-column">
        <div class="atit-wrap p2">
          <h2>비밀번호를 재설정<br>하실 수 있어요!</h2>
<!--          <span>가입하신 카카오톡&#40;문자&#41; 또는 메일로<br>새로운 비밀번호를 설정 하실 수 있는 링크를 보내 드려요.</span>-->
          <span>가입하신 메일로<br>새로운 비밀번호를 설정 하실 수 있는 링크를 보내 드려요.</span>
        </div>
        <div class="p2 flex-grow-1">
          <form @submit.prevent="">
            <div class="abox-cont">
              <div class="mb-4">
                <!-- form-control에 정보가 정확하면 is-valid, 잘못입력하면 is-invalid 클래스를 추가해 주세요 -->
                <div class="input-group has-validation">
                  <span class="input-group-text"><i class="icon-envelope"></i></span>
                  <input class="form-control binput" type="text" name="email" v-model="form.email"
                         :class="{ 'is-invalid': submitted && $v.form.email.$error}" placeholder="이메일 아이디를 입력해주세요.">
                  <div v-if="submitted && !$v.form.email.required" id="validationID" class="invalid-feedback">
                    이메일 주소를 확인해주세요
                  </div>
                </div>
              </div>
              <div class="mb-3 d-grid gap-3">
<!--                <button v-on:click="submitForm('Kakao')" class="btn btn-xl btn-primary">카카오톡&#40;문자&#41;로 받기</button>-->
                <button v-on:click="submitForm('Email')" class="btn btn-xl btn-primary">이메일로 받기</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="loader" v-show="progressing">
    <div class="lds-ellipsis">
      <img src="images/m.png" width="40px" alt="M">
      <div></div><div></div><div></div><div></div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import Footer from '@/components/Footer'
import axios from 'axios'

export default {
  name: 'PasswordReset',
  components: { Footer },
  data() {
    return {
      form: {
        email: null,
        sendType: null,
        companyId: process.env.VUE_APP_COMPANY_ID,
        frontend: 'monscent'
      },
      submitted: false,
      progressing: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    submitForm(sendType) {
      this.submitted = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.progressing = true

      const sForm = new FormData()
      sForm.append('email', this.form.email)
      sForm.append('sendType', sendType)
      sForm.append('companyId', this.form.companyId)
      sForm.append('frontend', this.form.frontend)

      axios.post('/api/resetPw', sForm).then(({ data }) => {
        alert('메일이 발송되었습니다.')
        this.progressing = false
        this.$router.push('/login')
      }).catch((error) => {
        alert(error.response.data.message)
        this.progressing = false
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>

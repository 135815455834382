<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light monavi">
    <div class="container-fluid max-limit">
      <button type="button" class="navbar-toggler navbtn" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a href="/home" class="navbar-brand logo"><img src="images/logo.png" width="90px" alt="Brand Logo Image"></a>
      <div class="collapse navbar-collapse navmenu" id="navbarNav">
        <div class="user-wrap" v-if="isLogin">
          <div class="m-log">
            {{ }}
          </div>
          <a href="/subscriptions" class="header-btn">마이페이지</a>
        </div>
        <div class="user-wrap" v-else>
          <div class="m-log">
            로그인 해주세요
          </div>
          <a href="/login" class="header-btn">로그인</a>
        </div>
        <ul class="navbar-nav flex-grow-1 justify-content-start">
          <li class="nav-item">
            <a href="/home#BrandStory" class="nav-link">
              브랜드스토리
              <i class="icon-arrow-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="/home#Advantage" class="nav-link">
              서비스 소개
              <i class="icon-arrow-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="/home#Subscribe" class="nav-link">
              구독상품
              <i class="icon-arrow-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="/home#faq" class="nav-link">
              자주묻는질문
              <i class="icon-arrow-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="/home#Contact" class="nav-link">
              Contact us
              <i class="icon-arrow-right"></i>
            </a>
          </li>
        </ul>
      </div>
<!--      <div class="cart">
        <button type="button" class="btn-cart" data-bs-toggle="modal" data-bs-target="#Cartbox">
          <i class="icon-basket"></i>
        </button>
      </div>-->
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navi',
  computed: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('userStore/logout').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>

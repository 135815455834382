<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 max-limit">
    <div class="cont-wrapper">
      <LGnb :url="url"></LGnb>
      <div v-if="loading && subscriptionList.length === 0" class="main">
        <div class="empty-cont">
          <span class="text">구독중인 상품이 없습니다.</span>
          <a href="/home#Subscribe" class="btn btn-xl btn-primary">구독 시작하기</a>
        </div>
      </div>
      <div v-else class="main-box">
        <div class="atit-wrap">
          <h2><i class="icon-bell"></i> 구독관리</h2>
        </div>
        <section class="subscribe-list">
          <div class="sub-title-wrap">
            <h3 class="title">구독중인 상품</h3>
          </div>
          <div class="subs-box" v-for="(subscription) in subscriptionList" :key="subscription.repeatOrderId">
            <div class="header">
              <div class="title">
                <em>다음 결제일</em>
                <span>{{ subscription.nextPaymentDay | moment("YYYY년MM월DD일") }} </span>
              </div>
              <button class="btn btn-sm" @click="detail(subscription)">상세보기 &#62;</button>
            </div>
            <ul class="item-wrap" v-for="(subsItem,index) in convertToObject(subscription.subscriptionOrderItemsJson)"
                v-bind:key="index">
              <li class="item-goods">
                <div class="item-thumb">
                  <img :src="subsItem.itemSmallImage" alt="썸네일"/>
                </div>
                <div class="item-cont-wrap">
                  <div class="item-info">
                    <span class="name">{{subsItem.itemName}}</span>
                    <span class="option"></span>
                  </div>
                  <div class="goods-price">
                    <div class="price-wrap">
                      <span class="qty">{{subsItem.itemCount}}개</span>
                      <span class="price">{{subsItem.itemSalePrice * subsItem.itemCount | makeComma}}원</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="subs-info">
<!--              <li>
                <i class="icon-event"></i>
                <div>
                  <em>구독일</em>
                  <span>{{ new Date(subscription.createDate) | moment("YYYY년MM월DD일") }} </span>
                </div>
              </li>-->
              <li>
                <i class="icon-refresh"></i>
                <div>
                  <em>구독주기</em>
                  <span>{{ subscription.period }}주 간격</span>
                </div>
              </li>
              <li>
                <i class="icon-credit-card"></i>
                <div>
                  <em>결제 수단</em>
                  <span>카카오 정기결제</span>
                </div>
              </li>
              <li>
                <i class="icon-wallet"></i>
                <div>
                  <em>결제 예정 금액</em>
                  <span>{{subsAmountCal(subscription) | makeComma}}원</span>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="subscribe-guide pt-4">
          <div class="sub-title-wrap">
            <h3 class="title">일반안내</h3>
          </div>
          <div class="subscribe-info">
            <ul>
              <li>구독 취소 및 구독일 변경은 구독중인 상품의 상세보기에서 변경 하실 수 있습니다.</li>
              <li>구독 자동 결제는 결제일 오전 10시에 자동 결제가 이루어집니다.</li>
              <li>반품은 고객센터를 통해서 처리가 가능합니다.</li>
              <li>단체 구매는 이메일과 유선으로 문의 주세요</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'
import LGnb from '@/components/mypage/LGnb'

export default {
  name: 'Subscriptions',
  components: {
    Navi, Footer, LGnb
  },
  data() {
    return {
      gcpUrl: process.env.VUE_APP_STORAGE,
      parentType: 'init',
      url: 'subscriptions',
      loading: false,
      subscriptionList: null
    }
  },
  created() {
    this.getSubscriptionList()
  },
  methods: {
    getSubscriptionList() {
      axios.get('/api/mypage/subscriptions').then(({ data }) => {
        console.log(data.result)
        this.subscriptionList = data.result
        this.loading = true
      }).catch((error) => {
        this.loading = true
        console.log(error)
      })
    },
    subsAmountCal(subscription) {
      const subsItems = this.convertToObject(subscription.subscriptionOrderItemsJson)
      let subsTotalAmount = 0
      for (let i = 0; i < subsItems.length; i++) {
        // subsTotalAmount += subsItems[i].subsAmount
        subsTotalAmount += subsItems[i].itemSalePrice * subsItems[i].itemCount
      }
      return subsTotalAmount
    },
    detail(subscription) {
      this.$router.push({ path: 'subscriptionDetail', query: { repeatOrderId: subscription.repeatOrderId } })
    },
    convertToObject(json) {
      return JSON.parse(json)
    }
  }
}
</script>

<style>
[v-cloak] {
  display: none;
}
</style>

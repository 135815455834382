import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

import Home from '@/views/Home'

import LoginPage from '@/views/member/LoginPage'
import RegisterPage from '@/views/member/RegisterPage'
import RegisterComplete from '@/views/member/RegisterComplete'
import FindId from '@/views/member/FindId'
import PasswordReset from '@/views/member/PasswordReset'
import ChangePW from '@/views/member/ChangePW'

import Subscriptions from '@/views/mypage/Subscriptions'
import SubscriptionDetail from '@/views/mypage/SubscriptionDetail'
import Orders from '@/views/mypage/Orders'
import OrderDetail from '@/views/mypage/OrderDetail'
import Qna from '@/views/mypage/Qna'
import QnaForm from '@/views/mypage/QnaForm'
import UserModify from '@/views/mypage/UserModify'
import UserWithdraw from '@/views/mypage/UserWithdraw'

import ItemDetail from '@/views/item/ItemDetail'

import SubscriptionOrderConfirm from '@/views/order/SubscriptionOrderConfirm'
import SubscriptionPayment from '@/views/order/SubscriptionPayment'
import SubscriptionOrderComplete from '@/views/order/SubscriptionOrderComplete'

import PrivacyTerms from '@/views/member/PrivacyTerms'
import ServiceTerms from '@/views/member/ServiceTerms'

const requireAuth = () => (from, to, next) => {
  if (store.getters['userStore/isLogin']) {
    return next()
  } else {
    next('/login?redirect=' + from.fullPath)
  }
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    redirect: '/home'
    /* name: 'SubscriptionItemDetail',
    component: SubscriptionItemDetail */
  }, {
    path: '/home',
    name: 'Home',
    component: Home
  }, {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  }, {
    path: '/findId',
    name: 'FindId',
    component: FindId
  }, {
    path: '/passwordReset',
    name: 'PasswordReset',
    component: PasswordReset
  }, {
    path: '/changePW',
    name: 'ChangePW',
    component: ChangePW
  }, {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  }, {
    path: '/registerComplete',
    name: 'RegisterComplete',
    component: RegisterComplete
  }, {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    beforeEnter: requireAuth()
  }, {
    path: '/subscriptionDetail',
    name: 'SubscriptionDetail',
    component: SubscriptionDetail,
    beforeEnter: requireAuth()
  }, {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    beforeEnter: requireAuth()
  }, {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: OrderDetail,
    beforeEnter: requireAuth()
  }, {
    path: '/qna',
    name: 'Qna',
    component: Qna,
    beforeEnter: requireAuth()
  }, {
    path: '/qnaForm',
    name: 'QnaForm',
    component: QnaForm,
    beforeEnter: requireAuth()
  }, {
    path: '/userModify',
    name: 'UserModify',
    component: UserModify,
    beforeEnter: requireAuth()
  }, {
    path: '/userWithdraw',
    name: 'UserWithdraw',
    component: UserWithdraw,
    beforeEnter: requireAuth()
  }, {
    path: '/itemDetail',
    name: 'ItemDetail',
    component: ItemDetail
  }, {
    path: '/subscriptionOrderConfirm',
    name: 'SubscriptionOrderConfirm',
    component: SubscriptionOrderConfirm
  }, {
    path: '/subscriptionPayment',
    name: 'SubscriptionPayment',
    component: SubscriptionPayment,
    beforeEnter: requireAuth()
  }, {
    path: '/subscriptionOrderComplete',
    name: 'SubscriptionOrderComplete',
    component: SubscriptionOrderComplete,
    beforeEnter: requireAuth()
  },
    {
      path: '/privacyTerms',
      name: 'PrivacyTerms',
      component: PrivacyTerms
    },
    {
      path: '/serviceTerms',
      name: 'ServiceTerms',
      component: ServiceTerms
    },
    {
      path: '*',
      name: 'Error404',
      component: () => import('@/views/Error404')
    }]
})

<template>
  <body class="d-flex flex-column h-100">
  <Navi></Navi>
  <div class="container-fluid main-cont flex-grow-1 max-limit">
    <div class="cont-wrapper">
      <LGnb :url="url"></LGnb>
      <div class="main-box">
        <section class="item-cont">
          <div class="sub-title-wrap">
            <h3 class="title">1:1문의 리스트</h3>
            <button class="btn btn-sm btn-primary" v-if="qnaList.length > 0" @click="goQna()">1:1문의하기</button>
          </div>
          <div class="question-box" v-for="(qna, $index) in qnaList" :key="$index">
            <div class="header">
              <span><i class="icon-speech"></i> {{getFaqCategoryName(qna)}}</span>
              <span><i class="icon-calendar"></i> {{ new Date(qna.createDate) | moment("YYYY.MM.DD") }}</span>
            </div>
            <div class="body" v-html="convertContent(qna.content)"></div>
            <div class="review-img"> <img v-if="qna.imageUrl1" :src="contentImage(qna)" alt="문의이미지"/></div>
            <div class="answer-wrap" v-if="qna.answer" v-html="convertContent(qna.answer)">
            </div>
            <div class="foot">
              <button class="btn btn-sm btn-outline-primary" @click="removeQna(qna)"><i class="icon-close"></i> 문의 삭제하기</button>
            </div>
          </div>
          <InfiniteLoading @infinite="infiniteHandler" spinner="spiral">
            <div slot="no-more"></div>
            <div slot="no-results">
              <div class="empty-cont">
                <img src="images/icon-question.png" alt="question icon">
                <span class="text">
                    1:1문의 내역이 없습니다.
                  </span>
                <div class="text-center">
                  <button class="btn btn-primary" @click="goQna()">1:1문의하기</button>
                </div>
              </div>
            </div>
          </InfiniteLoading>
        </section>
      </div>
    </div>
  </div>
  <Footer></Footer>
  </body>
</template>

<script>
import axios from 'axios'
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'
import LGnb from '@/components/mypage/LGnb'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Qna',
  components: {
    Navi, Footer, LGnb, InfiniteLoading
  },
  data() {
    return {
      url: 'qna',
      page: 0,
      size: 5,
      qnaList: [],
      isComplete: false,
      faqCategoryList: null
    }
  },
  created() {
    this.getFaqCategory()
  },
  methods: {
    infiniteHandler($state) {
      axios.get('/api/qna/list',
        { params: { page: this.page, size: this.size, sort: '' } }).then(({ data }) => {
        console.log(data.result)
        this.isComplete = true
        if (data.result.content.length) {
          this.page += 1
          for (let i = 0; i < data.result.content.length; i++) {
            this.qnaList.push(data.result.content[i])
          }
          $state.loaded()
        } else {
          $state.complete()
        }
      }).catch((error) => {
        console.log(error)
        $state.complete()
      })
    },
    getFaqCategory() {
      axios.get('/api/faq/codeList/FAQCategory').then(({ data }) => {
        console.log(data.result)
        this.faqCategoryList = data.result
      }).catch((error) => {
        console.log(error)
      })
    },
    getFaqCategoryName(qna) {
      return this.faqCategoryList.find(it => it.code === qna.faqCategory).codeName
    },
    convertContent(content) {
      return content.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    contentImage(content) {
      return process.env.VUE_APP_STORAGE + content.imageUrl1
    },
    goQna() {
      this.$router.push('/qnaForm')
    },
    removeQna(qna) {
      axios.get('/api/qna/removeQna/' + qna.qnaId).then(({ data }) => {
        console.log(data.result)
        alert('문의가 삭제되었습니다.')
        for (let i = 0; i < this.qnaList.length; i++) {
          if (this.qnaList[i].qnaId === qna.qnaId) {
            this.qnaList.splice(i, 1)
          }
        }
      }).catch((error) => {
        console.log(error.response.data.message)
        alert(error.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
